import { CancelIcon, QuestionMark } from "../../assets/icons";

export const RoAlertTypes = {
    Info: 'info',
    Danger: 'danger',
    Success: 'success',
}

export const mapIconToAlertType = {
    [RoAlertTypes.Info]: QuestionMark,
    [RoAlertTypes.Danger]: CancelIcon,
    [RoAlertTypes.Success]: QuestionMark
}