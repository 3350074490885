import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../Api";

export const getCustomRPA = createAsyncThunk('RPA/getCustomRPA', async(id) => {
    try{
        const rpa = await API.post('getCustomRPA', { clientId : id } );
        return rpa;
    } catch(err){
        console.error(err);
    }
})

export const createCustomRPA = createAsyncThunk('RPA/create', async({key, rpa_id, name, link, status}) => {
    let body_data = {
        key: key,
        rpa_id: rpa_id,
        name: name,
        link: link,
        status: status
    }
    try{
        const rpa = await API.post('createRPA_custom', body_data);
        console.log(rpa);
        return rpa;
    }catch(err){
        console.error(err);
    }
})

// CRUD operations for clients
export const getClients = createAsyncThunk('clients/get', async() => {
    try {
        const clients = await API.post('getSigninClient', {})
        return clients;
    }catch(err){
        console.error(err)
    }
})

export const addClient = createAsyncThunk('client/create', async({
    clientName,
    clientKey,
    provider,
    contactFirstName,
    contactLastName,
    contactPhone,
    contactEmail,
    tempPassword,
    confirmPassword,
    timeFrame,
    defaultPrice,
    defaultCharge,
    minimumTransaction,
    rpaEnabled,
    practices}) => {
    const body_data = {
        client_name: clientName,
        client_key: clientKey,
        provider: provider ? provider : 'Scheduling',
        first_name: contactFirstName,
        last_name: contactLastName,
        phone: contactPhone,
        email: contactEmail,
        password: tempPassword,
        confirmPassword: confirmPassword,
        tf_check_eligibility: timeFrame,
        price_per_transaction: defaultPrice,
        actice_provider: defaultCharge,
        minimum_transaction: minimumTransaction,
        RPAEnabled: rpaEnabled,
        practices: practices
    }
    try {
        console.log(body_data)
        const clients = await API.post('newClientSignup', body_data)
        return clients;
    }catch (err){
        console.log(err)
    }
})

export const verifyPractice = createAsyncThunk('client/verifyPractice', async(practiceData) => {
    console.log(practiceData.patientId)
    const data = {
        customerKey: practiceData.practiceInfo.key,
        user: practiceData.practiceInfo.email,
        password: practiceData.practiceInfo.password
    }
    console.log(data)
    try {
        const patient = await API.post(`client/verifyPatient/${practiceData.patientId}`, data);
        // console.log("patient", patient)
        return patient;
    }catch(err) {
        console.error(err);
    }
})

export const editClient = createAsyncThunk('client/edit', async({
    id,
    clientName,
    clientKey,
    status,
    provider,
    contactName,
    contactFirstName,
    contactLastName,
    contactPhone,
    contactEmail,
    timeFrame,
    defaultPrice,
    defaultCharge,
    minimumTransaction,
    practices, rpaEnabled}) => {
    const data = {
        id: id,
        client_name: clientName,
        client_key: clientKey,
        status: status,
        provider: provider ? provider : 'Scheduling',
        contactName,
        first_name: contactFirstName,
        last_name: contactLastName,
        phone: contactPhone,
        email: contactEmail,
        tf_check_eligibility: timeFrame,
        price_per_transaction: defaultPrice,
        actice_provider: defaultCharge ? defaultCharge : 'Yes',
        minimum_transaction: minimumTransaction,
        practices: practices,
        RPAEnabled: rpaEnabled
    }
    console.log("edit client", data)
    try {
        const client = await API.post('editClient', data);
        // console.log(client)
        return client;
    }catch(err){
        console.error(err);
    }
})

// activate/deactivate clients
export const clientStatus = createAsyncThunk('clients/changeStatus', async({clientId, status}) => {
    const data = {
        clientId: clientId,
        status: status
    }
    try{
        const clientStatus = await API.put('deactive_activeClients', data);
        return clientStatus;
    }catch(err){
        console.error(err);
    }
})

// mark encounters as reviewed for clients
export const markAllAsReviewed = createAsyncThunk('client/markAsReviewed', async({key, deleteItems, practiceIds}) => {
    const data = {
        key: key,
        deleteItems: deleteItems,
        practiceIds: practiceIds
    }
    try {
        const markAsReview = await API.post('mark_all_as_review', data)
        // console.log(markAsReview);
        return markAsReview;
    }catch(err){
        console.error(err);
    }
})

// manual auto run for clients
export const manualAutoRun = createAsyncThunk('client/manualAutoRun', async({key, practiceIds}) => {
    const data = {
        key: key,
        practiceIds: practiceIds
    }
    console.log("manual auto run", data)
    try {
        const manualAutorun = await API.post('manual_auto_run', data)
        // console.log(manualAutorun);
        return manualAutorun;
    }catch(err){
        console.error(err);
    }
})

export const login = createAsyncThunk('login', async({email, key, password}) => {
    let body_data = {
        email: email,
        key: key,
        password: password
    }
    console.log(body_data)
    try {
        const login = await API.post('login', body_data);
        // console.log(login);
        return login;
    }catch(err) {
        console.error(err);
    }
})

// CRUD operations for invoice
export const getInvoice = createAsyncThunk('invoice/get', async(date) => {
    try {
        const invoice = await API.get(`getInvoice/${date}`);
        // console.log(invoice);
        return invoice;
    }catch(err){
        console.error(err);
    }
})

export const editInvoice = createAsyncThunk('invoice/edit', async({
    id, price_per_transaction, defaultChargePerProvider, minimum_transaction, providers
}) => {
    const data = {
        id: id,
        price_per_transaction: price_per_transaction,
        defaultChargePerProvider: defaultChargePerProvider,
        minimum_transaction: minimum_transaction,
        providers: providers
    }
    console.log(data)
    try {
        const invoice = await API.put('editInvoice', data);
        console.log(invoice);
        return invoice;
    }catch(err) {
        console.error(err);
    }
})

export const updateInvoiceStatus = createAsyncThunk('invoice/updateInvoiceStatus', async({id, status}) => {
    console.log(id, status)
    try {
        const invoiceStatus = await API.put(`updateStatus/${id}`, {status: status});
        console.log(invoiceStatus)
        return invoiceStatus;
    }catch(err){
        console.error(err);
    }
})

export const openErrorDetails = createAsyncThunk('openErrorDetails', async (reqPayLoad) => {
    try {
        const { data } = await API.post(`openErrorDetails`, reqPayLoad);
        return data;
    }catch(err){
        throw err;
    }
})

// API to get logs
export const getAllbugs = createAsyncThunk('getAllbugs', async (payload) => {
    try {
        const {data} = await API.post(`getAllbugs`, payload);
        return data;
    } catch (error) {
        throw error;
    }
})

export const updateBugsAtAdminConsole = createAsyncThunk('updateBugsAtAdminConsole', async (reqPayLoad) => {
    try {
        console.log('reqPayLoad ', reqPayLoad)
        const { data } = await API.post(`updateBugsAtAdminConsole/${reqPayLoad.id}`, reqPayLoad);
        return data
    } catch (error) {
        throw error;
    }
})

// CRUD operations for practices
export const getPractices = createAsyncThunk('practices/get', async({id}) => {
    try{
        const practices = await API.post('getClientPractices', {id: id});
        console.log(practices);
        return practices;
    }catch(err){
        console.error(err);
    }
})

export const addPractice = createAsyncThunk('practices/add', async({
    id, practiceName, providerType, acronym, email, password, key, practiceNPI
}) => {
    let body_data = {id, practiceName, providerType, acronym, email, password, key, practiceNPI};
    try{
        const practice = await API.post('addClientPractice', body_data);
        console.log(practice);
        return practice;
    }catch(err){
        console.error(err);
    }
})

export const editPractice = createAsyncThunk('practices/edit', async({id, practiceId, providerType, status}) => {
    let body_data = {id, practiceId, providerType, status};
    try{
        const practice = await API.put('editClientPractice', body_data);
        console.log(practice);
        return practice;
    }catch(err){
        console.error(err);
    }
})