export const navItems = [
    {
        path: './admin-panel/clients',
        title: 'CLIENTS'
    },
    {
        path: './admin-panel/invoice',
        title: 'INVOICING'
    },
    // {
    //     path: './admin-panel/tickets',
    //     title: 'TICKETS'
    // }
]