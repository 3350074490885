import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogContent,
    DialogTitle,
    Button
} from "@mui/material";
import { systemAdminDo, systemAdminDont } from "./UserData";
import emailIcon from '../../assets/icons/practiceEmail.svg';
import calendarIcon from '../../assets/icons/practiceCalendar.svg';
import showPassword from '../../assets/icons/showPassword.svg';
import hidePassword from '../../assets/icons/hidePassword.svg';
import { deleteUser, editUser } from "./settingsActions";

const EditUser = (props) => {
    const dispatch = useDispatch();
    const [userCanDo, setUserCanDo] = useState(['Everything']);
    const [userCantDo, setUserCantDo] = useState(['Nothing']);
    const [userInfo, setUserInfo] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        roleid: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [showPass, setShowPass] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);

    let userData = props?.userData

    useEffect(() => {
        let user = props?.userData;
        setUserInfo({
            first_name: user?.first_name,
            last_name: user?.last_name,
            email: user?.email,
            password: user?.password,
            roleid: user?.roleid,
            status: user?.status
        })
    }, [props.userData])

    // function to handle input fields
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setUserInfo({...userInfo, [name]: value});
    }

    // function to edit user
    const editAdminUser = (id) => {
        let body_data = {
            id: id,
            first_name: userInfo?.first_name,
            last_name: userInfo?.last_name,
            email: userInfo?.email,
            roleid: userInfo?.roleid,
            newPassword: userInfo?.newPassword
        }
        console.log(body_data);
        dispatch(editUser(body_data)).then((res) => {
            console.log(res.payload);
            props.closeEdit();
        })
    }

    // function to deactivate user
    const deactivateUser = () => {
        const data = userInfo;
        if(data.status === true){
            data["status"] = false;
        }else if(data.status === false){
            data["status"] = true;
        }
        console.log("clientInfo", data);
        dispatch(editUser(data)).then((response) => {
            console.log(response);
            props.closeEdit();
            props.users();
        }).catch(err => {
            console.error(err);
        })
    }

    // function to delete user
    const handleDeleteUser = (email) => {
        console.log(email)
        dispatch(deleteUser({email: email})).then((res) => {
            let user = res.payload;
            console.log(user);
            props.closeEdit();
            props.users();
        })
    }

    return (
        <>
            {props.openEdit && (
                <Dialog
                    fullWidth
                    open={props.openEdit}
                    onClose={props.closeEdit}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="modalTitle">
                        <DialogTitle id="alert-dialog-title">
                            <span className="font-face-futura">{"Edit User"}</span>
                        </DialogTitle>
                    </div>
                    <DialogContent>
                        <Card className="my-3 userCard" style={{ height: "150px" }}>
                            <CardContent className="userContent">
                                <span className="headingUserName m-left">
                                    {userInfo?.first_name + " " + userInfo?.last_name}
                                </span>
                                <div className="row">
                                    <div className="col-lg-3 col-sm-3">
                                        <div className="Logo_name_user">
                                            <div className="logoFieldUser">
                                                <p>
                                                    {(userInfo?.first_name + " " + userInfo?.last_name || "").match(/\b(\w)/g)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-sm-5">
                                        <div className="col">
                                            <div className="row userCred">
                                                <span>
                                                    <img
                                                        src={emailIcon}
                                                        alt="practiceEmailIcon"
                                                        className="me-2"
                                                    />
                                                    <strong>Email: </strong>
                                                    {userInfo.email}
                                                </span>
                                            </div>
                                            <div className="row">
                                                <span>
                                                    <img
                                                        src={calendarIcon}
                                                        alt="practiceCalendar"
                                                        className="me-2"
                                                    />
                                                    <strong>Invited: </strong>
                                                    {new Date().toLocaleDateString("en-US")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-4">
                                        <div className="btnDiv">
                                            <Button
                                                variant="contained"
                                                className="deactivateBtn mx-2"
                                                value={userData?.status === true ? true : false}
                                                onClick={deactivateUser}
                                            >
                                                <span>{userData?.status ? "DEACTIVATE" : "ACTIVATE"}</span>
                                            </Button>
                                            <Button
                                                variant="contained"
                                                className="deleteBtn"
                                                style={{whiteSpace:'nowrap'}}
                                                onClick={() => handleDeleteUser(userData?.email)}
                                            >
                                                <span>Delete User</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>

                        <div className="mt-2">
                            <Card className="modalCard" style={{ height: "190px" }}>
                                <CardHeader
                                    className="userCardHead"
                                    title={
                                        <h5 className="text-white font-face-futura">Profile</h5>
                                    }
                                />
                                <CardContent className="userContent">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-6">
                                            <label htmlFor="first_name">
                                                <strong>First Name:</strong>
                                            </label>
                                            <input
                                                className="user-Input"
                                                name="first_name"
                                                value={userInfo?.first_name}
                                            // value={
                                            //     userInfo?.first_name.charAt(0).toUpperCase() +
                                            //     userInfo?.first_name.slice(1)
                                            // }
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                            <label htmlFor="last_name">
                                                <strong>Last Name:</strong>
                                            </label>
                                            <input
                                                className="user-Input"
                                                name="last_name"
                                                value={userInfo?.last_name}
                                            // value={
                                            //     userInfo?.last_name.charAt(0).toUpperCase() + userInfo?.last_name.slice(1)
                                            // }
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="mt-2 col-lg-12 col-sm-12">
                                            <label htmlFor="email">
                                                <strong>Email</strong>
                                            </label>
                                            <input
                                                className="user-Input"
                                                name="email"
                                                value={userInfo.email}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>

                        <div className="mt-2">
                            <Card className="userCard" style={{ height: "250px" }}>
                                <CardHeader
                                    className="userCardHead"
                                    title={
                                        <h5 className="text-white font-face-futura">
                                            Password Management
                                        </h5>
                                    }
                                />
                                <CardContent className="userContent">
                                    <div className="row">
                                        <div className="form-input">

                                            <label htmlFor="currentPassword">
                                                <strong>Current Password:</strong>
                                            </label>
                                            <input
                                                className="user-Input"
                                                type={showPass ? "text" : "password"}
                                                name="currentPassword"
                                                value={userInfo.password}
                                                onChange={handleInputChange}
                                            />
                                            <img
                                                src={showPass ? showPassword : hidePassword}
                                                alt="password"
                                                onClick={() => setShowPass(!showPass)}
                                                className="pass-btn eye-btn"
                                                />
                                        </div>
                                        <div className="form-input">
                                            <label htmlFor="newPassword">
                                                <strong>New Password</strong>
                                            </label>
                                            <input
                                                type={showNewPass ? "text" : "password"}
                                                className="user-Input"
                                                name="newPassword"
                                                value={userInfo.newPassword}
                                                onChange={handleInputChange}
                                            />
                                            <img
                                                src={showNewPass ? showPassword : hidePassword}
                                                alt="password"
                                                onClick={() => setShowNewPass(!showNewPass)}
                                                className="pass-btn eye-btn"
                                            />
                                        </div>
                                        <div className="form-input">
                                            <label htmlFor="confirmPassword">
                                                <strong>Confirm Password</strong>
                                            </label>
                                            <input
                                                type={showConfirmPass ? "text" : "password"}
                                                className="user-Input"
                                                name="confirmPassword"
                                                value={userInfo.confirmPassword}
                                                onChange={handleInputChange}
                                            />
                                            <img
                                                src={showConfirmPass ? showPassword : hidePassword}
                                                alt="password"
                                                onClick={() => setShowConfirmPass(!showConfirmPass)}
                                                className="pass-btn eye-btn"
                                            />
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>

                        <div className="mt-1">
                            <Card className="mt-2 userCard">
                                <CardHeader
                                    className="userCardHead"
                                    title={
                                        <h5 className="text-white font-face-futura">
                                            Permissions
                                        </h5>
                                    }
                                />
                                <CardContent>
                                    <div align="center">
                                        <span className="contentUser">
                                            Choose the users site permissions
                                        </span>
                                    </div>
                                    <div align="center">
                                        <span className="mt-1 contentText ">
                                            Let’s get them set up!
                                        </span>
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="roleid"><strong className='strongTxt2'>Permission Level:</strong></label>
                                        <div className="boxSizingDrop">
                                            <select
                                                fullWidth="true" name="roleid"
                                                className="modal_select font-face-futura"
                                                value={userInfo.roleid}
                                                onChange={(e) => {
                                                    this.handlePermissionChange(e.target.value);
                                                }}
                                            >
                                                <option value="">Select</option>
                                                <option value="1">System Administrator</option>
                                                {/* <option value="select">Select</option> */}
                                                {/* {permissionValues &&
                                                    permissionValues.map((options, idx) => (
                                                    <option key={idx} value={options.id}>
                                                        {options.role_Name}
                                                    </option>
                                                    ))} */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <div className="row font-face-futura">
                                            <div className="col-lg-6 col-sm-6">
                                                <div className="headingsText">
                                                    <strong>What can they do?</strong>
                                                </div>
                                                <ul className="mt-2 headingsText">
                                                    {userCanDo &&
                                                        userCanDo.map((canDo, idx) => (
                                                            <li key={idx}>{canDo}</li>
                                                        ))}
                                                </ul>
                                            </div>

                                            <div className="col-lg-6 col-sm-6">
                                                <div className="headingsText">
                                                    <strong>What can't they do?</strong>
                                                </div>
                                                <div className="mt-2 headingsText">
                                                    {userCantDo &&
                                                        userCantDo.map((cantDo, idx) => (
                                                            <li key={idx}>{cantDo}</li>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>

                        <footer className='set-btn'>
                            <div className='actionButton'>
                                <Button fullWidth
                                    className="mt-4 mx-2 nevermindBtn"
                                    variant="outlined" onClick={props.closeEdit}>
                                    <span className="btnSpan">Nevermind</span>
                                </Button>
                                <Button fullWidth
                                    className="mt-4 mx-2 addClientBtn"
                                    variant="outlined" onClick={() => editAdminUser(userData?.id)}>
                                    <span className="btnSpan">Save</span>
                                </Button>
                            </div>
                        </footer>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
}
export default EditUser;
