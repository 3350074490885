import { createSlice } from "@reduxjs/toolkit";
import { changePassword, forgotPassword, userLogin, verifyLoginOTP, verifyOTP } from "./authAction";

// initialize userToken from session storage
const userToken = sessionStorage.getItem('userToken') ? sessionStorage.getItem('userToken') : null;

const initialState = {
    loading: false,
    userInfo: null,
    userToken,
    isUserLoggedIn: false,
    error: null,
    success: false
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // logout user
        logout: (state) => {
            sessionStorage.removeItem('userToken')
            state.loading = false
            state.isUserLoggedIn = false
            state.userInfo = null
            state.userToken = null
            state.error = null
        }
    },
    extraReducers: {
        // login user
        [userLogin.pending]: (state) => {
            state.loading = true
            state.isUserLoggedIn = false
            state.error = null
        },
        [userLogin.fulfilled]: (state, {payload}) => {
            state.loading = false
            state.isUserLoggedIn = true
            state.userInfo = payload
        },
        [userLogin.rejected]: (state, {payload}) => {
            state.loading = false
            state.isUserLoggedIn = false
            state.error = payload.message
        },
        
        // otp verification actions
        [verifyLoginOTP.pending]: (state) => {
            state.loading = true;
        },
        [verifyLoginOTP.fulfilled]: (state, {payload}) => {
            state.loading = false;
            state.success = true;
            state.userToken = payload
        },
        [verifyLoginOTP.rejected]: (state, {payload}) => {
            state.loading = false;
            state.success = false;
            state.error = payload;
        },

        // forgot password actions
        [forgotPassword.pending]: (state) => {
            state.loading = true;
        },
        [forgotPassword.fulfilled]: (state, {payload}) => {
            state.loading = false;
            state.success = true;
        },
        [forgotPassword.rejected]: (state, {payload}) => {
            state.loading = false;
            state.success = false;
            state.error = payload;
        },

        // change password verify otp actions
        [verifyOTP.pending]: (state) => {
            state.loading = true;
        },
        [verifyOTP.fulfilled]: (state, {payload}) => {
            state.loading = false;
            state.success = true;
        },
        [verifyOTP.rejected]: (state, {payload}) => {
            state.loading = false;
            state.success = false;
            state.error = payload;
        },

        // change password actions
        [changePassword.pending]: (state) => {
            state.loading = true;
        },
        [changePassword.fulfilled]: (state, {payload}) => {
            state.loading = false;
            state.success = true;
        },
        [changePassword.rejected]: (state, {payload}) => {
            state.loading = false;
            state.success = false;
            state.error = payload;
        }
    }
})

export const { logout } = authSlice.actions
export default authSlice.reducer