import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import "./Login.css";
import { Card, CardContent, CardHeader } from "@mui/material";
import logo from "../../../assets/icons/logo.svg";
import API from "../../../Api";
import { NotificationManager } from "react-notifications";
import { changePassword, forgotPassword, verifyOTP } from "./authAction";
import AlertMessages from "../../Layouts/Alerts/Alert";

const ForgotPassword = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let user = location.state;

    const [passwordInfo, setPasswordInfo] = useState({
        OTP: "",
        password: "",
        confirmPassword: "",
    });
    const [errors, setErrors] = useState({});
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [isAccRegistration, setIsAccRegistration] = useState(true);
    const [userName, setUserName] = useState(user.first_name);

    /**
     * function to handle chnage in input fields
     * @param {*} e 
     */
    const handleChange = (e) => {
        const { name, value } = e.target;
        errors[name] = "";
        setPasswordInfo({ ...passwordInfo, [name]: value });
        setErrors(errors);
    };

    /**
     * function is use for validation in OTP
     */
    const isValidOtp = () => {
        const validation = {};
        let valid = true;
        if (!passwordInfo.OTP) {
            valid = false;
            validation.OTP = "*Please enter the code that was emailed to you";
        }
        setErrors(validation);
        return valid;
    };

    /**
     * Function is used for validation in set new password
     */
    const isValidPassword = () => {
        const validPassword = {};
        let valid = true;
        let validatePassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
        if (!passwordInfo.password) {
            valid = false;
            validPassword.password = "*Please enter the new password";
        } else if (passwordInfo.password.length < 8 || passwordInfo.password.length > 20) {
            valid = false;
            validPassword.password = "*Password must be in between 8 to 20 characters";
        } else if (!passwordInfo.password.match(validatePassword)) {
            valid = false;
            validPassword.password = "*Password must contain atleast one uppercase, one lowercase, one number and a special character";
        } else if (!passwordInfo.confirmPassword) {
            valid = false;
            validPassword.confirmPassword = "*Please enter the confirm password";
        } else if (passwordInfo.password !== passwordInfo.confirmPassword) {
            valid = false;
            validPassword.confirmPassword = "*Password does not match";
        }
        setErrors(validPassword);
        return valid;
    }

    /**
     * function to handle chnage password button after fill successful OTP
     */
    const onChangePassword = () => {
        if (isValidOtp()) {
            let body = {
                email: user?.email,
                OTP: passwordInfo.OTP
            }
            dispatch(verifyOTP(body)).then((res) => {
                if (res.payload.data.code === 200) {
                    AlertMessages("OTP verified successfully", "Success!", 2000);
                    setIsAccRegistration(false);
                    setIsChangePassword(true);
                } else {
                    AlertMessages("Incorrect OTP", "Error", 2000);
                }
            })
        }
    };

    /**
     * Function is used to resend the OTP for varification
     */
    const onResendMail = () => {
        let body = {
            email: user?.email
        }
        dispatch(forgotPassword(body)).then((res) => {
            if (res.payload.data.code === 200) {
                AlertMessages("OTP send successfully on registered mail id", "Success!", 2000);
            } else {
                AlertMessages(res.payload.data.message, 'Error', 2000);
            }
        })
        .catch((error) => {
            return error;
        });
    };

    /**
     * function is used for set the new password and navigate to login Page.
     */
    const onLogin = () => {
        if (isValidPassword()) {
            let body = {
                email: user?.email,
                password: passwordInfo.password,
                confirmPassword: passwordInfo.confirmPassword
            }
            dispatch(changePassword(body)).then((res) => {
                if (res.payload.data.status === 200) {
                    AlertMessages('Password changed successfully', "Success!", 2000);
                    navigate("/")
                } else {
                    AlertMessages("Something went wrong!", "Failed", 2000);
                }
            }).catch((err) => {
                return err;
            });
        }
    };


    const style = {
        textAlign: "left",
        height: 0,
        lineHeight: "16px",
    };


    return (
        <section className="page-backGround">
            <div className="d-flex justify-content-center align-items-center h-100">
                <Card variant="outlined" className="loginCard h100">
                    <CardContent>
                        <div className="row customize-dflex">
                            <div className="col-6 col-sm-12 col-lg-6 col-md-12 d-flex align-items-center">
                                <img src={logo} alt="logo" width={420} height={155} />
                            </div>
                            {isAccRegistration && (
                                <div className="col-6 col-sm-12 col-lg-6 col-md-12 font-face-poppins text-align">
                                    <CardHeader
                                        title={
                                            <span className="font-face-poppins pageHeadings">
                                                Let’s Verify Your Account
                                            </span>
                                        }
                                    />
                                    <div>
                                        <span className="">Welcome {userName} !</span>
                                    </div>
                                    <div className="mt-4">
                                        <label htmlFor="OTP">
                                            What is the code that was emailed to you?
                                        </label>
                                        <input
                                            className="inputFields"
                                            name="OTP"
                                            value={passwordInfo.OTP}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <p className="text-danger" style={style}>
                                            {errors.OTP}
                                        </p>
                                    </div>

                                    <div className="mt-3">
                                        <button className="changePswrd" onClick={onChangePassword}>
                                            <span>Change Password</span>
                                        </button>
                                    </div>

                                    <div className="termsCondition mt-2">
                                        <p>
                                            By logging in you agree to the
                                            <a
                                                href="/"
                                                style={{ textDecoration: "none", color: "#81C3D7" }}
                                            >
                                                {" "}
                                                terms{" "}
                                            </a>{" "}
                                            and{" "}
                                            <a
                                                href="/"
                                                style={{ textDecoration: "none", color: "#81C3D7" }}
                                            >
                                                conditions
                                            </a>
                                        </p>
                                    </div>
                                    <div className="mt-5 pb-2">
                                        <span>Didn’t receive it?</span>
                                    </div>
                                    <div className="mt-2">
                                        <button
                                            className="resendPswrd"
                                            onClick={() => onResendMail()}
                                        >
                                            <span>Resend Code</span>
                                        </button>
                                    </div>
                                </div>
                            )}
                            {isChangePassword && (
                                <div className="col-6 col-sm-12 col-lg-6 col-md-12 font-face-poppins text-align">
                                    <CardHeader
                                        title={
                                            <span className="font-face-poppins pageHeadings">
                                                Account Registration
                                            </span>
                                        }
                                    />
                                    <div>
                                        <span className="">Welcome {userName}!</span>
                                    </div>
                                    <div className="mt-4">
                                        <label htmlFor="password">Password:</label>
                                        <input
                                            type="password"
                                            name="password"
                                            value={passwordInfo.password}
                                            className="inputFields"
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <p className="text-danger" style={style}>
                                            {errors.password}
                                        </p>
                                    </div>
                                    <div className="mt-4">
                                        <label htmlFor="confirmPassword">
                                            Password Confirmation:
                                        </label>
                                        <input
                                            type="password"
                                            name="confirmPassword"
                                            value={passwordInfo.confirmPassword}
                                            className="inputFields"
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <p className="text-danger" style={style}>
                                            {errors.confirmPassword}
                                        </p>
                                    </div>
                                    <div className="mt-2">
                                        <button className="changePswrd" onClick={onLogin}>
                                            <span>Log In</span>
                                        </button>
                                    </div>
                                    <div className="termsCondition mt-2 pb-3">
                                        <p>
                                            By logging in you agree to the
                                            <a
                                                href="/"
                                                style={{ textDecoration: "none", color: "#81C3D7" }}
                                            >
                                                {" "}
                                                terms{" "}
                                            </a>{" "}
                                            and{" "}
                                            <a
                                                href="/"
                                                style={{ textDecoration: "none", color: "#81C3D7" }}
                                            >
                                                conditions
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </CardContent>
                </Card>
            </div>
        </section>
    );
};
export default ForgotPassword;
