import { React, useEffect, useState } from "react";
import { Card, CardContent, CardHeader, Button } from "@mui/material";
import { CircularProgress } from '@mui/material';
import logo from "../../assets/icons/logo.svg";
import API from "../../Api";
import { NavLink, useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { acceptInvite } from "./settingsActions";

const loaderStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  bgcolor: 'transparent',
  color: '#24476a'
};
const buttonStyle = {
  backgroundColor: "#24476a",
  color: "white",
  width: "400px",
};


const InviteUser = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(false);
  const [errors, setErrors] = useState({});
  const [inviteMessage, setInviteMessage] = useState("");
  const [inviteInfo, setInviteInfo] = useState({
    email: "",
    password: "",
    confirmPassword: ""
  });

  // function to handle input value change
  const handleChange = (e) => {
    const {name, value} = e.target;
    errors[name] = '';
    setInviteInfo({...inviteInfo, [name]: value});
    setErrors(errors);
  }

  const isValidPassword = () => {
    const validPassword = {};
    let valid = true;
    let validatePassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    if(!inviteInfo.client_key) {
      valid = false;
      validPassword.client_key = "*Please enter client key"
    }
    if(!inviteInfo.password) {
      valid = false;
      validPassword.password = "*Please enter the new password"
    } else if(inviteInfo.password.length < 8 || inviteInfo.password.length > 20){
      valid = false;
      validPassword.password = "*Password must be in between 8 to 20 characters"
    } else if(!inviteInfo.password.match(validatePassword)){
      valid =  false;
      validPassword.password = "*Password must contain atleast one uppercase, one lowercase, one number and a special character"
    } else if(!inviteInfo.confirmPassword){
      valid = false;
      validPassword.confirmPassword = "*Please reconfirm the password"
    } else if(inviteInfo.password !== inviteInfo.confirmPassword){
      valid = false;
      validPassword.confirmPassword = "*Passwords do not match"
    }
    setErrors(validPassword);
    return valid;
  }

  const handleAcceptInvite = () => {
    dispatch(acceptInvite(inviteInfo)).then((res) => {
      let invite = res.payload.data;
      console.log(invite);
      if(invite.status === 200){
        navigate('/');
      }
      setInviteInfo({
        password: '',
        confirmPassword: ''
      });
    })
  }

  const style = {
    textAlign: "left",
    height: 0,
    lineHeight: "16px",
  };

  const inputStyle = {
    width: '400px',
    height: '40px',
    borderRadius: '5px 5px 5px 3px',
    border: '1px solid #C4C4C4'
  }

  useEffect(() => {
    console.log(props.history)
    if (window.location.href?.split("?")[1]?.split("=")[0] === "invite") {
      let inviteToken = window.location.href?.split("?")[1]?.split("=")[1];
      let x = jwtDecode(inviteToken)
      console.log(x)
      setInviteInfo({email: x.email})
      API.post("acceptAdminUserInvite?invite=" + inviteToken).then((res) => {
        console.log(res.data.message)
        setIsLoaded(true)
        setInviteMessage(res.data?.message === "Success" ? `Welcome, ${res.data?.name}` : res.data?.message)
      }).catch(err => {
        console.error(err);
      })
    }
  }, [])


  return (
    <section className="page-backGround">
      <div className="d-flex justify-content-center align-items-center h-100">
        <Card variant="outlined" className="loginCard h100">
          <CardContent>
            <div className="row customize-dflex">
              <div className="col-6 col-sm-12 col-lg-6 col-md-12 d-flex align-items-center">
                <img src={logo} alt="logo" width={420} height={155} />
              </div>
              {isLoaded ? (<div className="col-6 col-sm-12 col-lg-6 col-md-12 font-face-poppins text-align">
                <CardHeader
                  title={
                    <div>
                      <span className="font-face-poppins pageHeadings">
                        Account Registration
                      </span><br />
                      <span className="font-face-poppins pageSubHeading">{inviteMessage}!</span>
                    </div>
                  }
                />
                <div>
                  <label htmlFor="email" style={{ fontSize: 13 }}>
                    Password:
                  </label>
                  <br />
                  <input
                    fullWidth
                    className="p-2"
                    type="password"
                    name="password"
                    value={inviteInfo.password}
                    style={inputStyle}
                    onChange={(e) => handleChange(e)}
                  />
                  <p className="text-danger" style={style}>
                    {errors.password}
                  </p>
                </div>

                <div>
                  <label htmlFor="password" style={{ fontSize: 13 }}>
                    Password Confirmation:
                  </label>
                  <br />
                  <input
                    fullWidth
                    className="p-2"
                    type="password"
                    name="confirmPassword"
                    value={inviteInfo.confirmPassword}
                    style={inputStyle}
                    onChange={(e) => handleChange(e)}
                  />
                  <p className="text-danger" style={style}>
                    {errors.confirmPassword}
                  </p>
                </div>

                <div className="pb-2">
                  <Button
                    fullWidth
                    variant="contained"
                    className="text-capitalize"
                    style={buttonStyle}
                    onClick={handleAcceptInvite}
                  >
                    <span className="font-face-poppins" style={{ fontSize: 13 }}>Create User</span>
                  </Button>
                </div>
              </div>) : (<div className="col-6 col-sm-12 col-lg-6 col-md-12 font-face-poppins text-align">
                <CardHeader
                  title={
                    <span className="font-face-poppins pageHeadings">
                      Let’s Wait For User Confirmation
                    </span>
                  }
                />
                <div>
                  <CircularProgress style={loaderStyle} />
                </div>
                <div>
                  {/* <span className="">Welcome {userName}!</span> */}
                </div>
              </div>)}

            </div>
          </CardContent>
        </Card>
      </div>
    </section>
  );
};
export default InviteUser;
