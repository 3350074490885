import { createSlice } from "@reduxjs/toolkit";
import { getClients, addClient, verifyPractice, editClient, login, markAllAsReviewed, manualAutoRun, 
    getPractices, addPractice, editPractice, getCustomRPA, clientStatus, createCustomRPA} from "../adminAction";

const initialState = {
    clients: [],
    isLoading: false,
    isSuccess: false,
    errorMessage: '',
    clientInfo: null,
    patientInfo: null,
    loginInfo: null,
    clientDetails: null,
    practices: [],
    rpaRows: null,
    rpaInfo: null
}

export const clientSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {},
    extraReducers: {
        // Custom RPA
        [getCustomRPA.pending]: (state) => {
            state.isLoading = true;
        },
        [getCustomRPA.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.rpaRows = payload;
        },
        [getCustomRPA.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload;
        },

        [createCustomRPA.pending]: (state) => {
            state.isLoading = true;
        },
        [createCustomRPA.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.rpaInfo = payload;
        },
        [createCustomRPA.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload;
        },

        // get clients list actions
        [getClients.pending]: (state) => {
            state.isLoading = true;
        },
        [getClients.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.clients = payload;
        },
        [getClients.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload;
        },

        // add client actions
        [addClient.pending]: (state) => {
            state.isLoading = true;
        },
        [addClient.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.clientInfo = payload;
        },
        [addClient.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.errorMessage = payload.message
        },

        // verify practice actions
        [verifyPractice.pending]: (state) => {
            state.isLoading = true;
        },
        [verifyPractice.fulfilled]: (state, payload) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.patientInfo = payload;
        },
        [verifyPractice.rejected]: (state, payload) => {
            state.isLoading = false;
            state.errorMessage = payload.message
        },

        // get practices actions
        [getPractices.pending]: (state) => {
            state.isLoading = true;
        },
        [getPractices.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.practices = payload;
        },
        [getPractices.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload;
        },

        // add parctice actions
        [addPractice.pending]: (state) => {
            state.isLoading = true;
        },
        [addPractice.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.practices = payload;
        },
        [addPractice.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload;
        },

        // edit practice actions
        [editPractice.pending]: (state) => {
            state.isLoading = false;
        },
        [editPractice.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.practices = payload;
        },
        [editPractice.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload;
        },

        // edit client actions
        [editClient.pending]: (state) => {
            state.isLoading = true;
        },
        [editClient.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.clientInfo = payload;
        },
        [editClient.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload;
        },

        // client status actions
        [clientStatus.pending]: (state) => {
            state.isLoading = true;
        },
        [clientStatus.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.clientInfo = payload;
        },
        [clientStatus.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload;
        },

        // mark as review actions
        [markAllAsReviewed.pending]: (state) => {
            state.isLoading = true;
        },
        [markAllAsReviewed.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.clientDetails = payload;
        },
        [markAllAsReviewed.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload;
        },

        // manual auto run actions
        [manualAutoRun.pending]: (state) => {
            state.isLoading = true;
        },
        [manualAutoRun.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.clientDetails = payload;
        },
        [manualAutoRun.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.clientDetails = payload;
        },

        // login actions
        [login.pending]: (state) => {
            state.isLoading = true;
        },
        [login.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.loginInfo = payload;
        },
        [login.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload;
        }
    }
})

export default clientSlice.reducer