import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../../Api";

export const history = {
    navigate: null,
    location: null
}

export const userLogin = createAsyncThunk('login', async ({ email, password }) => {
    try {
        const { data } = await API.post('admin_login', { email, password })
        // store user's token in local storage
        console.log(data, "djhgf")
        // if(data.code === 200) {
        //     sessionStorage.setItem('userToken', data.token)
        //     API.defaults.headers.Authorization = `Bearer ${data.token}`
        // }
        return data;
    } catch (err) {
        console.error(err);
    }
})

export const verifyLoginOTP = createAsyncThunk('otpVerificationLogin', async({email, OTP}) => {
    try{
        const code = await API.post('otpVerifyLoginAdminUser', {email, OTP})
        // store user's token in session storage
        console.log(code);
        if(code.data.code === 200) {
            sessionStorage.setItem('userToken', code.data.token)
            // API.defaults.headers.Authorization = `Bearer ${code.data.token}`
            API.defaults.headers.Authorization = "Bearer "+ code.data.token
        }
        return code;
    }catch(err){
        console.error(err);
    }
})

export const forgotPassword = createAsyncThunk('forgotPassword', async({email}) => {
    try{
        const forgot = await API.post('forgotAdminUserPassword', {email});
        console.log(forgot);
        return forgot;
    }catch(err){
        console.error(err);
    }
})

export const verifyOTP = createAsyncThunk('veriftOtp', async({email, OTP}) => {
    try{
        const code = await API.post('otpAdminUserVerify', {email, OTP});
        console.log(code);
        return code;
    }catch(err){
        console.error(err);
    }
})

export const changePassword = createAsyncThunk('changePassword', async({email, password, confirmPassword}) => {
    try{
        const pass = await API.post('setAdminUserPassword', {email, password, confirmPassword});
        console.log(pass);
        return pass;
    }catch(err){
        console.error(err);
    }
})