import React from 'react';
import {AppBar, IconButton, Menu, MenuItem} from '@mui/material';
import RuleOutLogo from '../../../assets/icons/RuleOutWhite.svg';
import Settings from '../../../assets/icons/settings.svg';
import './navbar.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Auth/Login/authSlice';
import { navItems } from './constants';
import { jwtDecode } from 'jwt-decode';

export const Navbar = () => {
    // const {userToken} = useSelector((state) => state.auth);
    const userToken = sessionStorage.getItem('userToken') ? sessionStorage.getItem('userToken') : null
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [anchorElUser, setAnchorElUser] = React.useState(null);

    // only show nav when logged in
    if (!userToken) return null;

    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };

    let token = jwtDecode(sessionStorage.getItem('userToken'));
    console.log(token)


    return (
        <>
            <AppBar position='fixed'>
                <header className='navHead'>
                    <div className='row'>
                        <div className='col-lg-9 col-xxl-9 col-sm-9'>
                            <div className='head_logo'>
                                <img src={RuleOutLogo} alt="ruleout" />
                                <div className='ml-80 font-face-futura'>
                                    {navItems.map((item, idx) => (
                                        <NavLink to={item.path} className="mx-4 navLinks" defaultValue={"CLIENTS"}>{item.title}</NavLink>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-xxl-3 col-sm-3'>
                            <div className='settings_head'>
                                <img src={Settings} alt="settings" style={{cursor: 'pointer'}} onClick={() => navigate('/settings')} />

                                <IconButton onClick={handleOpenUserMenu}>
                                    <div className='mx-4 userInitials'>
                                        <span className='text-white initialsTxt'>{((token?.name)?.toUpperCase())?.match(/\b(\w)/g)}</span>
                                    </div>
                                </IconButton>
                                <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                                >
                                    <MenuItem onClick={handleCloseUserMenu}>
                                        <span className='font-face-futura' onClick={() => dispatch(logout())}>Log Out</span>
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </header>
            </AppBar>
        </>
    )
}