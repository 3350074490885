import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Card, CardContent, CardHeader, IconButton, Tab, TablePagination, Tabs, Tooltip } from '@mui/material';
import editIcon from '../../../assets/icons/settingEdit.svg';
import { clientStatus, editClient, editPractice, getPractices, getCustomRPA, getClients } from '../adminAction';
import { editMasterSettings, updateRPA } from '../../Settings/settingsActions';
import practiceCalendar from '../../../assets/icons/practiceCalendar.svg';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { NavigateNext } from '@mui/icons-material';
import MarkAsReview from './MarkAsReview';
import AutoRun from './AutoRun';
import AddPracticeModal from './AddPracticeModal';
import io from "socket.io-client";
import hidePassword from '../../../assets/icons/hidePassword.svg';
import showPassword from '../../../assets/icons/showPassword.svg';
import AddRPA from './AddRPA';

const EditClient = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();

    // console.log(location.state.clientData)

    let clientDetails = location.state.clientData;

    const [clientInfo, setClientInfo] = useState({
        clientName: '',
        clientKey: '',
        status: '',
        deactivatedDate: '',
        provider: '',
        contactFirstName: '',
        contactLastName: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        confirmPassword: '',
        timeFrame: '',
        defaultPrice: '',
        defaultCharge: '',
        minimumTransaction: '',
        RPAEnabled: ''
    })
    const [practices, setPractices] = useState([]);
    const [disableProfileFields, setDisableProfileFields] = useState(true);
    const [disableInvoiceFields, setDisableInvoiceFields] = useState(true);
    const [tabValue, setTabValue] = useState(0);
    const [openMarkAllReview, setOpenMarkAllReview] = useState(false);
    const [openAutoRun, setOpenAutoRun] = useState(false);
    const [openAddPractice, setOpenAddPractice] = useState(false);
    const [practiceIdx, setPracticeIdx] = useState(null);
    const [lastTimeMarkasReview, setLastTimeMarkasReview] = useState(null)
    // pagination constants
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [trackPracticeStatus, setTrackPracticeStatus] = useState({
        completed: [],
        queue: [],
        runing: [],
        status: false
    })
    const [RPAList, setRPAList] = useState();
    const [toggleId, setToggleId] = useState();
    const [tempPass, setTempPass] = useState('password');
    const [openAddRPA, setOpenAddRPA] = useState(false);

    const handleMarkAllReview = () => setOpenMarkAllReview(!openMarkAllReview);
    const handleAutoRun = () => setOpenAutoRun(!openAutoRun);

    // function to handle page change
    const handlePageChange = (event, currentPage) => {
        setPage(currentPage);
    };

    // function to handle rows per page
    const handleRowsPerPage = (event) => {
        let rowspage = parseInt(event.target.value, 10);
        setRowsPerPage(rowspage);
        setPage(0);
    };

    // function to handle tab value
    const handleTabs = (e, tabValue) => {
        setTabValue(tabValue);
    }

    // function to toggle password visibility
    const showTempPass = (rpaId) => {
        RPAList.filter((rpa, idx) => {
            if (rpaId === idx) {
                if (tempPass === 'password') {
                    setTempPass('input');
                } else {
                    setTempPass('password');
                }
            }
        })
    }

    // function to handle edit rpa data
    const toggleRPAEdit = (rpaId) => {
        if (rpaId === toggleId) {
            setToggleId(null)
        } else {
            setToggleId(rpaId)
        }
    }

    // funtion to handle input change
    const handleRPAInputs = (e, index) => {
        let { name, value } = e.target;
        setRPAList(RPAList => RPAList.map((rpa, idx) => index === idx
            ? {
                ...rpa,
                [name]: value,
            }
            : rpa
        ));
    }

    // function to add new row for RPA
    const handleRPAPopup = () => {
        setOpenAddRPA(!openAddRPA);
    }

    const getCustomRPAList = () => {
        dispatch(getCustomRPA(clientDetails?.id)).then((response) => {
            setRPAList(response.payload.data.data)
        })
    }

    // function to edit custom RPA data
    const editCustomRPA = (rpaId) => {
        toggleRPAEdit();
        let selectedRPA = RPAList.filter(rpa => rpa.id === rpaId);
        console.log(selectedRPA)
        let id = selectedRPA[0]?.id;
        let clientId = clientDetails?.id;
        let name = selectedRPA[0]?.name;
        let link = selectedRPA[0]?.link;
        let status = selectedRPA[0]?.status;
        let body_data = {
            id: id,
            clientId: clientId,
            name: name,
            link: link,
            status: status
        }
        dispatch(updateRPA(body_data)).then(res => {
            console.log(res);
        })
    }

    var socket;
    try {
        socket = io.connect("https://backenddev.ruleout.com/");
    } catch (error) {
        console.log(error)
    }
    useEffect(() => {
        if (socket) {
            socket.emit("trackPracticeStatus", {
                clientId: clientDetails.id
            })

            socket.on("trackPracticeStatus", (data) => {
                console.log('trackPracticeStatus ', data.queue.length, data.runing.length)

                if (data.queue.length !== 0 || data.runing.length !== 0) {
                    data.status = true
                } else {
                    data.status = false;
                }
                setTrackPracticeStatus({ ...data })
                let abc = trackPracticeStatus.completed.sort((a, b) => {
                    return b.runingTime - a.runingTime
                })[0]?.runingTime;
            })
        }
    }, []);

    // useEffect(() => {
    //     const clientData = clientDetails;
    //     setClientInfo({
    //         clientName: clientData?.client_name,
    //         clientKey: clientData?.client_key,
    //         status: clientData?.status,
    //         deactivatedDate: clientData?.deactivatedDate,
    //         provider: clientData?.provider,
    //         contactFirstName: clientData?.first_name,
    //         contactLastName: clientData?.last_name,
    //         contactName: clientData?.first_name + ' ' + clientData?.last_name,
    //         contactEmail: clientData?.email,
    //         contactPhone: clientData?.phone,
    //         timeFrame: clientData?.tf_check_eligibility,
    //         defaultPrice: clientData?.price_per_transaction,
    //         defaultCharge: clientData?.actice_provider,
    //         minimumTransaction: clientData?.minimum_transaction,
    //         RPAEnabled: clientData?.RPAEnabled
    //     })
    // }, [props.clientData])

    // function to get practices
    useEffect(() => {
        let id = clientDetails?.id;
        dispatch(getPractices({ id: id })).then((res) => {
            let practiceList = res.payload.data.data;
            setPractices(practiceList);
            let practiceItemForMARdate = JSON.stringify(practiceList)
            let marDate = (JSON.parse(practiceItemForMARdate) || []).sort((p1, p2) => (p1?.manualMarkAsReviewTime < p2?.manualMarkAsReviewTime) ? 1 : (p1?.manualMarkAsReviewTime > p2?.manualMarkAsReviewTime) ? -1 : 0)[0]?.manualMarkAsReviewTime
            setLastTimeMarkasReview(marDate)
        });
    }, [0])

    // function to toggle edit
    const toggleEdit = (toggleId) => {
        if (toggleId === practiceIdx) {
            setPracticeIdx(null);
        } else {
            setPracticeIdx(toggleId);
        }
    }

    // function to edit invoice settings
    const editInvoiceSettings = () => {
        // setDisableFields("")
        let data = {
            timeFrameForCheckingEligibility: clientInfo.timeFrame,
            defaultPricePerTransaction: clientInfo.defaultPrice,
            defaultChargePerProvider: clientInfo.defaultCharge,
            minimumTransactionPerProvider: clientInfo.minimumTransaction
        }
        dispatch(editMasterSettings(data)).then((res) => {
            console.log(res)
        })
    }

    // function to handle input value change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setClientInfo({ ...clientInfo, [name]: value });
    }

    // function to handle practice data value change
    const handlePracticeData = (e, index) => {
        let { name, value } = e.target;
        if (name === "status") {
            value = (value === 'Active')
        }
        setPractices(practices => practices.map((item, idx) => index === idx
            ? {
                ...item,
                [name]: value,
            }
            : item
        ));
    }

    // function to edit practice
    const editClientPractice = (pracId) => {
        toggleEdit();
        console.log(practices, pracId)
        let selectedPractice = practices.filter(item => item.id === pracId);
        let practiceId = selectedPractice[0]?.id;
        let providerType = selectedPractice[0]?.providerType;
        let status = selectedPractice[0]?.status;
        let body_data = {
            id: clientDetails?.id,
            practiceId: practiceId,
            providerType: providerType,
            status: status
        }
        console.log(body_data, selectedPractice, providerType, status)
        dispatch(editPractice(body_data)).then((res) => {
            console.log(res.payload);
        }).catch(err => {
            console.error(err);
        })
    }

    // function to add a new client
    const handleEditClient = () => {
        // console.log("practiceInfo", practiceInfo, clientInfo);
        const data = clientInfo;
        data["id"] = clientDetails?.id;
        data["practices"] = practices
        console.log("clientInfo", clientInfo);
        dispatch(editClient(data)).then((res) => {
            console.log(res);
            let client = res.payload.data.data[0];
            setClientInfo({
                clientName: client?.client_name,
                clientKey: client?.client_key,
                status: client?.status,
                deactivatedDate: client?.deactivatedDate,
                provider: client?.provider,
                contactFirstName: client?.first_name,
                contactLastName: client?.last_name,
                contactEmail: client?.email,
                timeFrame: client?.tf_check_eligibility,
                defaultPrice: client?.price_per_transaction,
                defaultCharge: client?.actice_provider,
                minimumTransaction: client?.minimum_transaction,
                RPAEnabled: client?.RPAEnabled
            })
            setDisableProfileFields(true);
            setDisableInvoiceFields(true);
        }).catch(err => {
            console.error(err);
        })
    }

    // function to handle edit client values
    useEffect(() => {
        let clients;
        dispatch(getClients(clients)).then(res => {
            clients = res.payload.data.list;
            let client = clients.filter(item => item.id === clientDetails?.id);
            // console.log(client[0]);
            setClientInfo({
                clientName: client[0]?.client_name,
                clientKey: client[0]?.client_key,
                status: client[0]?.status,
                deactivatedDate: client[0]?.deactivatedDate,
                provider: client[0]?.provider,
                contactFirstName: client[0]?.first_name,
                contactLastName: client[0]?.last_name,
                contactName: client[0]?.first_name + ' ' + client[0]?.last_name,
                contactEmail: client[0]?.email,
                contactPhone: client[0]?.phone,
                timeFrame: client[0]?.tf_check_eligibility,
                defaultPrice: client[0]?.price_per_transaction,
                defaultCharge: client[0]?.actice_provider,
                minimumTransaction: client[0]?.minimum_transaction,
                RPAEnabled: client[0]?.RPAEnabled
            })
        })
    }, [0])

    // function to handle deactivate client
    const deactivateClient = (e) => {
        const { value } = e.target;
        const data = clientInfo;
        if (data.status === true) {
            data["status"] = false;
        } else if (data.status === false) {
            data["status"] = true;
        }
        data["id"] = clientDetails?.id;
        let body_data = {
            clientId: data?.id,
            status: data?.status
        }
        dispatch(clientStatus(body_data)).then((res) => {
            let clientStatus = res.payload.data.data[0].status;
            setClientInfo({ status: clientStatus, deactivatedDate: res.payload.data.data[0].deactivatedDate });
        }).catch(err => {
            console.error(err);
        })
    }

    // function to handle add parctice pop up
    const handleAddPracticeModal = () => {
        setOpenAddPractice(!openAddPractice);
    }

    useEffect(() => {
        console.log(RPAList)
        dispatch(getCustomRPA(clientDetails.id)).then((response) => {
            setRPAList(response.payload.data.data)
        })
    }, [])
    useEffect(() => {
        // console.log(RPAList)
        // dispatch(getCustomRPA(clientDetails.id)).then((response) => {
        //     setRPAList(response.payload.data.data)
        // })
    }, [RPAList])

    return (
        <section className='edit_cl_scn'>
            <div className="row">
                <div className="col-lg-12 col-sm-12 client_link">
                    <p>
                        <a href="/admin-panel/clients" style={{ textDecoration: 'none', color: '#535353' }}>Clients</a>
                        <NavigateNext style={{ color: '#7BBC9B' }} /><span>Edit Client</span>
                    </p>
                </div>
            </div>

            <div>
                <Card variant="outlined" className="col-lg-12 col-sm-12 info-box">
                    <CardContent style={{ width: '100%', height: '100%' }}>
                        <div className='row' style={{ height: '100%' }}>
                            <div className="col-lg-8 col-sm-10 details_text_sec">
                                {/* <p>{state.practiceName}</p> */}
                                <p className='m-0'>{clientDetails?.client_name}</p>
                                <p className='m-0 mt-3'>
                                    <img src={practiceCalendar} alt="practiceCalendar" className='practiceCalendar' />&nbsp;
                                    <span className='ptc'><strong>Added:</strong>&nbsp;
                                        {new Date(clientDetails?.created_at).toLocaleDateString('en-US')}
                                    </span>
                                </p>
                                {clientInfo?.status === false && <p className='m-0 mt-2'>
                                    <img src={practiceCalendar} alt="practiceCalendar" className='practiceCalendar' />&nbsp;
                                    <span className='ptc'><strong>Deactivated:</strong>&nbsp;
                                        {clientInfo?.deactivatedDate}
                                    </span>
                                </p>}
                            </div>
                            <div className="col-lg-4 d-flex justify-content-end button_deactive">
                                <div className='d-flex align-items-end pe-4'>
                                    <Tooltip
                                        // title={roleDetails.roleId ===1 ? "" : "you don't have permission"}
                                        arrow
                                    >
                                        <span className='font-face-futura'>
                                            <Button
                                                className="text-white mark mx-2" style={{ width: '120px' }}
                                                // disabled={roleDetails.roleId === 1 ? false : true}
                                                value={clientInfo?.status === true ? true : false}
                                                variant="contained"
                                                onClick={deactivateClient}
                                            >{clientInfo?.status === true ? 'Deactivate' : 'Activate'}
                                            </Button>
                                        </span>
                                    </Tooltip>
                                    <Tooltip
                                        // title={roleDetails.roleId ===1 ? "" : "you don't have permission"}
                                        arrow
                                    >
                                        <span className='font-face-futura'>
                                            <Button
                                                className="text-white save"
                                                // disabled={roleDetails.roleId === 1 ? false : true}
                                                variant="contained"
                                            // onClick={() => this.setState({openUsersModal: true})}
                                            >Open
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>

                            {/* {openUsersModal && <Dialog fullWidth
                                open={openUsersModal}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description">
                                <div align="center" className='planTitle'>
                                    <DialogTitle id="alert-dialog-title">
                                        <span className='font-face-futura'>{"Practice Users"}</span>
                                    </DialogTitle>
                                </div>
                                <DialogContent>
                                    <div className='mt-2'>
                                        <Card className='planCard'>
                                            <CardHeader className='planCardHead' title={<h5 className="text-white font-face-futura">Profile</h5>} />
                                            <CardContent className='planContent'>
                                                <div className="row">
                                                    <div className='col-lg-12 col-sm-12'>
                                                        <label htmlFor="practiceName"><strong>Practice Name:</strong></label>
                                                        <input className='disable practice-input'
                                                            name="practiceName"
                                                            value={practiceName}
                                                            disabled
                                                            // onChange={this.handleInputChange} 
                                                            />
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                        <Card className="mt-2 planCard pos-relative">
                                            <CardHeader className='planCardHead'
                                                title={<h5 className="text-white font-face-futura">User Access</h5>} />
                                            <CardContent className='editPracticeUser pb-2'>
                                                <input className='p-2 text modifier-searchs'
                                                    type='text'
                                                    value={userSearchKey}
                                                    placeholder='Type something to search...'
                                                    onChange={(e) => {
                                                        this.searchValues(e.target.value)
                                                    }}
                                                    /><br />
                                                <div className='drop'>
                                                    {userList?.length > 0 ? (
                                                        userList?.map((option, idx) => (
                                                        <div key={idx} className='optionDrop'>
                                                            <RoCheckbox
                                                            style={{ width: 18, height: 18 }}
                                                            value={option.id}
                                                            checked={selectedUserId?.includes(option.id)}
                                                            onChange={(e) =>
                                                                this.handleCheckbox(e.target.value)
                                                            }
                                                            />
                                                            <span className='spantext'>
                                                                {option.first_name + " " + option.last_name}
                                                            </span>
                                                        </div>
                                                        ))
                                                    ): (
                                                        <span className="noRecord">No Result found!</span>
                                                    )}
                                                </div>
                                            </CardContent>
                                        </Card>
                                        <footer>
                                            <div className='actionButton'>
                                                <Button fullWidth
                                                    className="mt-2 mb-2 mx-2 nevermindBtn"
                                                    variant="outlined" 
                                                    onClick={this.closeUserModal}
                                                    >
                                                    <span className="text-white font-face-futura" style={{ fontSize: 18 }}>Nevermind</span>
                                                </Button>
                                                <Button fullWidth
                                                    className="mt-2 mb-2 mx-2 linkBtnAfter"
                                                    variant="outlined"
                                                    onClick={this.updatePracticeUser}
                                                    >
                                                    <span className="text-white font-face-futura" style={{ fontSize: 18 }}>Update Users</span>
                                                </Button>
                                            </div>
                                        </footer>
                                    </div>
                                </DialogContent>
                            </Dialog>} */}
                        </div>
                    </CardContent>
                </Card>
            </div>

            <div className='my-3'>
                <Card className='modalCard' style={{ height: '390px', width: '99.5%' }}>
                    <CardHeader className='modalCardHead' title={<h5 className="my-2 text-white text-start font-face-futura">Profile</h5>} />
                    <CardContent className='modalContent'>
                        <div className="row text-start mx-2">
                            <div className='col-lg-12 col-sm-12'>
                                <label htmlFor="clientName"><strong>Client Name:</strong></label>
                                <input className={disableProfileFields ? 'inputField disabled' : 'inputField'}
                                    name="clientName"
                                    disabled={disableProfileFields ? 'disabled' : ''}
                                    value={clientInfo.clientName} onChange={handleInputChange} />
                                {/* <p className="text-danger" style={style}>{errors.practiceName}</p> */}
                            </div>
                            <div className='my-3 col-lg-12 col-sm-12'>
                                <label htmlFor="clientKey"><strong>Client Key:</strong></label>
                                <input className={disableProfileFields ? 'inputField disabled' : 'inputField'}
                                    name="clientKey"
                                    disabled={disableProfileFields ? 'disabled' : ''}
                                    value={clientInfo.clientKey} onChange={handleInputChange} />
                                {/* <p className="text-danger" style={style}>{errors.practiceName}</p> */}
                            </div>
                            <div className='col-lg-6 col-sm-6'>
                                <label htmlFor="contactFirstName"><strong>Contact First Name:</strong></label>
                                <input className={disableProfileFields ? 'inputField disabled' : 'inputField'}
                                    name="contactFirstName"
                                    disabled={disableProfileFields ? 'disabled' : ''}
                                    value={clientInfo.contactFirstName} onChange={handleInputChange} />
                                {/* <p className="text-danger" style={style}>{errors.practiceAcronym}</p> */}
                            </div>
                            <div className='col-lg-6 col-sm-6'>
                                <label htmlFor="contactLastName"><strong>Contact Last Name:</strong></label>
                                <input className={disableProfileFields ? 'inputField disabled' : 'inputField'}
                                    name="contactLastName"
                                    disabled={disableProfileFields ? 'disabled' : ''}
                                    value={clientInfo.contactLastName} onChange={handleInputChange} />
                                {/* <p className="text-danger" style={style}>{errors.practiceAcronym}</p> */}
                            </div>
                            <div className='my-3 col-lg-12 col-sm-12'>
                                <label htmlFor="contactEmail"><strong>Contact Email:</strong></label>
                                <input className={disableProfileFields ? 'inputField disabled' : 'inputField'}
                                    name="contactEmail"
                                    disabled={disableProfileFields ? 'disabled' : ''}
                                    value={clientInfo.contactEmail} onChange={handleInputChange} />
                                {/* <p className="text-danger" style={style}>{errors.practiceEmail}</p> */}
                            </div>
                        </div>
                        <div className='col d-flex justify-content-end pe-4 my-2 pracButtonDiv'>
                            <Tooltip
                            // title={roleDetails.roleId === 1 ? "" : "you don't have permission"}
                            >
                                <span className="text-white font-face-futura">
                                    <Button className='mark mx-2 my-1'
                                        variant="contained"
                                        // disabled={roleDetails.roleId === 1 ? false : true}
                                        // className={this.state.profileButtonText === 'Edit' ? "m-1 pracEditBtn" : "m-1 saveBtn"}
                                        onClick={() => setDisableProfileFields(!disableProfileFields)}
                                    >
                                        <span className='font-face-futura'>Edit</span>
                                    </Button>
                                    <Button className='save'
                                        variant="contained"
                                        // disabled={roleDetails.roleId === 1 ? false : true}
                                        // className={this.state.profileButtonText === 'Edit' ? "m-1 pracEditBtn" : "m-1 saveBtn"}
                                        onClick={handleEditClient}
                                    >
                                        <span className='font-face-futura'>Save</span>
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                    </CardContent>
                </Card>
            </div>

            <div>
                <Card className="col-lg-12 col-sm-12 modalCard mt-3" variant="outlined">
                    <CardHeader className='modalCardHead' title={<h5 className="mt-2 font20 text-white text-start font-face-futura">Practice Settings</h5>} />
                    <CardContent className="modalContent">
                        <Tabs
                            value={tabValue}
                            onChange={handleTabs}
                            variant="fullWidth"
                            indicatorColor="primary">
                            <Tab label="Auto-Run" className='font20' />
                            <Tab label="Invoicing" className='font20' />
                            <Tab label="RPA" className='font20' />
                        </Tabs>
                        {tabValue === 0 &&
                            <>
                                <div
                                    // style={{pointerEvents: roleDetails.roleId === 1 ? "auto" : "none" }} 
                                    align="center" className="my-4 mb-3 row rules-box">
                                    <div className='col-lg-12 col-sm-12'>
                                        <table className='table-borderless rules-wdt'>
                                            <tr>
                                                <td>
                                                    <span>Mark All as Reviewed:</span><br />
                                                    <span className='subtext'><b>Last time Marked All as Reviewed:</b> {lastTimeMarkasReview ? new Date(lastTimeMarkasReview).toLocaleDateString('en-US') + " @ " + new Date(lastTimeMarkasReview).toLocaleTimeString() : null}</span>
                                                </td>&nbsp;&nbsp;&nbsp;
                                                <td>
                                                    <Button className='mark' onClick={() => handleMarkAllReview()}><span>MARK ALL</span></Button>
                                                </td>
                                            </tr><br />
                                            <tr>
                                                <td>
                                                    <span>Auto-Run Rules:</span><br />
                                                    <span className='subtext'><b>Last time Manually Ran:</b>{' '}
                                                        {trackPracticeStatus?.status === true ? 'In Progress...' : new Date(
                                                            (trackPracticeStatus.completed || []).sort((a, b) => {
                                                                return b?.runingTime - a?.runingTime
                                                            })[0]?.runingTime).toLocaleDateString('en-US') + " @ " + new Date(
                                                                (trackPracticeStatus.completed || []).sort((a, b) => {
                                                                    return b?.runingTime - a?.runingTime
                                                                })[0]?.runingTime).toLocaleTimeString()
                                                        }

                                                        <br></br></span>
                                                    {trackPracticeStatus?.status === true ?
                                                        <>
                                                            <span><b>In Progress : </b><br></br></span>

                                                            {(trackPracticeStatus.completed || []).map((item) => (
                                                                <>
                                                                    <span className='subtext'>{item.name} (Completed {new Date(item.runingTime).toLocaleDateString() + ' @ ' + new Date(item.runingTime).toLocaleTimeString()})</span>
                                                                    <br></br>
                                                                </>
                                                            ))}
                                                            {(trackPracticeStatus.runing || []).map((item) => (
                                                                <>
                                                                    <span className='subtext'>{item.name} (In Progress...)</span>
                                                                    <br></br>
                                                                </>
                                                            ))}
                                                            {(trackPracticeStatus.queue || []).map((item) => (
                                                                <>
                                                                    <span className='subtext'>{item.name} (In Queue)</span>
                                                                    <br></br>
                                                                </>
                                                            ))}
                                                        </>
                                                        : <>
                                                            {(trackPracticeStatus.completed || []).map((item) => (
                                                                <>
                                                                    <span className='subtext'>{item.name} (Completed)</span>
                                                                    <br></br>
                                                                </>
                                                            ))}</>}
                                                </td>&nbsp;&nbsp;&nbsp;
                                                <td>
                                                    <Button className='mark' onClick={() => handleAutoRun()}><span>RUN NOW</span></Button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                                <MarkAsReview
                                    openMarkAsReview={openMarkAllReview}
                                    closeMarkAllReview={handleMarkAllReview}
                                    setLastTimeMarkasReview={setLastTimeMarkasReview}
                                    clientDetails={clientDetails} />
                                <AutoRun
                                    openAutoRun={openAutoRun}
                                    closeAutoRun={handleAutoRun}
                                    clientDetails={clientDetails} />
                            </>
                        }
                        {tabValue === 1 &&
                            <>
                                <div className='masterDiv'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-sm-12 position-relative'>
                                            <label htmlFor="time"><strong>Time Frame for Checking Eligibility:</strong></label>
                                            <select className={disableInvoiceFields ? 'disabled modal_select' : 'modal_select'}
                                                disabled={disableInvoiceFields ? 'disabled' : ''}
                                                value={clientInfo.timeFrame}
                                                name='timeFrame' onChange={handleInputChange}>
                                                <option value="Every DOS">Every DOS</option>
                                                <option value="Weekly">Weekly</option>
                                                <option value="Monthly">Monthly</option>
                                            </select>
                                            {/* {disableInvoiceFields === "timeFrame" ?
                                                <DoneIcon className='done' onClick={editInvoiceSettings} /> :
                                                <img src={editIcon} alt="edit" className='form-btn' name="timeFrame" onClick={(e) => toggleEdit(e, "timeFrame")} />
                                            } */}
                                            {/* <p className="text-danger" style={style}>{errors.practiceName}</p> */}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='my-2 col-lg-12 col-sm-12 position-relative'>
                                            <label htmlFor="defaultPrice"><strong>Default Price Per Transaction:</strong></label>
                                            <input className={disableInvoiceFields ? 'inputField disabled' : 'inputField'}
                                                disabled={disableInvoiceFields ? 'disabled' : ''}
                                                value={clientInfo.defaultPrice}
                                                name="defaultPrice" onChange={handleInputChange} />
                                            {/* {disableInvoiceFields === "defaultPrice" ?
                                                <DoneIcon className='done' onClick={editInvoiceSettings} /> :
                                                <img src={editIcon} alt="edit" className='form-btn' name="defaultPrice" onClick={(e) => toggleEdit(e, "defaultPrice")} />
                                            } */}
                                            {/* <p className="text-danger" style={style}>{errors.practiceAcronym}</p> */}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='my-2 col-lg-12 col-sm-12 position-relative'>
                                            <label htmlFor="defaultCharge"><strong>Default Charge Per Active Provider?</strong></label>
                                            <select className={disableInvoiceFields ? 'disabled modal_select' : 'modal_select'}
                                                disabled={disableInvoiceFields ? 'disabled' : ''}
                                                value={clientInfo.defaultCharge}
                                                name='defaultCharge' onChange={handleInputChange}>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                            {/* {disableInvoiceFields === "defaultCharge" ?
                                                <DoneIcon className='done' onClick={editInvoiceSettings} /> :
                                                <img src={editIcon} alt="edit" className='form-btn' name="defaultCharge" onClick={(e) => toggleEdit(e, "defaultCharge")} />
                                            } */}
                                            <small className='smallTxt'>Clients will not be able to run claims for providers that are not active in their practice settings</small>
                                            {/* <p className="text-danger" style={style}>{errors.practiceEmail}</p> */}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='my-2 col-lg-12 col-sm-12 position-relative'>
                                            <label htmlFor="rpaEnabled"><strong>RPA Enabled?</strong></label>
                                            <select className={disableInvoiceFields ? 'disabled modal_select' : 'modal_select'}
                                                disabled={disableInvoiceFields ? 'disabled' : ''}
                                                value={clientInfo.RPAEnabled}
                                                name='rpaEnabled' onChange={handleInputChange}>
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                            <small className='smallTxt'>Clients will not be able to select RPA Flow if disabled</small>
                                            {/* <p className="text-danger" style={style}>{errors.practiceEmail}</p> */}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='my-2 col-lg-12 col-sm-12 position-relative'>
                                            <label htmlFor="minimumTransaction"><strong>Minimum Transaction Per Provider:</strong></label>
                                            <input className={disableInvoiceFields ? 'inputField disabled' : 'inputField'}
                                                disabled={disableInvoiceFields ? 'disabled' : ''}
                                                value={clientInfo.minimumTransaction}
                                                name="minimumTransaction" onChange={handleInputChange} />
                                            {/* {disableInvoiceFields === "minTrans" ?
                                                <DoneIcon className='done' onClick={editInvoiceSettings} /> :
                                                <img src={editIcon} alt="edit" className='form-btn' name="minTrans" onClick={(e) => toggleEdit(e, "minTrans")} />
                                            } */}
                                            {/* <p className="text-danger" style={style}>{errors.practicePassword}</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='my-2 d-flex align-items-center justify-content-end'>
                                    <Button className='mark' onClick={() => setDisableInvoiceFields(!disableInvoiceFields)}>
                                        <span className='subtext'>Edit</span>
                                    </Button>
                                    <Button className='save mx-3' onClick={handleEditClient}><span className='subtext'>Save</span></Button>
                                </div>
                            </>
                        }
                        {tabValue === 2 &&
                            <>
                                <Card className="col-lg-12 col-sm-12 mt-4 mb-4 modalCard" variant="outlined">
                                    <CardContent className="rpaContent">
                                        <div className='table-responsive font-face-futura'>
                                            <table className='table client-table'>
                                                <thead className='tableHead text-white'>
                                                    <tr>
                                                        <th>RPA ID</th>
                                                        <th>RPA Name</th>
                                                        <th>API Link</th>
                                                        <th>Created Date</th>
                                                        <th>Modified Date</th>
                                                        <th>Status</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {RPAList && RPAList?.length > 0 ?
                                                        (RPAList
                                                            .slice(
                                                                page * rowsPerPage,
                                                                page * rowsPerPage + rowsPerPage
                                                            ).map((rpa, idx) => (
                                                                <tr key={idx}>
                                                                    <td>
                                                                        <input className={toggleId === rpa.id ? 'rpa_input text-center' : 'noInput'}
                                                                            type='text'
                                                                            name='rpa_id' style={{ width: '80px' }}
                                                                            value={rpa.rpa_id} onChange={(e) => handleRPAInputs(e, idx)} />
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        <input className={toggleId === rpa.id ? 'rpa_input text-center' : 'noInput'}
                                                                            type='text' id="rpa_name"
                                                                            name="name"
                                                                            value={rpa.name} onChange={(e) => handleRPAInputs(e, idx)} />
                                                                    </td>
                                                                    <td className='text-center one-line-ellipsis' title={rpa.link}>
                                                                        <input className={toggleId === rpa.id ? 'rpa_input text-center' : 'noInput'}
                                                                            type={toggleId === rpa.id ? tempPass : 'password'}
                                                                            name="link"
                                                                            value={rpa.link} onChange={(e) => handleRPAInputs(e, idx)} />
                                                                        {toggleId === rpa.id &&
                                                                            <img src={tempPass === 'password' ? hidePassword : showPassword}
                                                                                alt="password" className='form-img2' onClick={() => showTempPass(idx)} />}
                                                                    </td>
                                                                    <td>{(1 + new Date(rpa.createdAt).getMonth() + '/' + new Date(rpa.createdAt).getDate() + '/' + new Date(rpa.createdAt).getFullYear())}</td>
                                                                    <td>{1 + new Date(rpa.createdAt).getMonth() + '/' + new Date(rpa.updatedAt).getDate() + '/' + new Date(rpa.createdAt).getFullYear()}</td>
                                                                    <td className='text-center'>
                                                                        {toggleId === rpa.id ?
                                                                            <select className='rpa_select text-center' name='status'
                                                                                value={rpa.status}
                                                                                onChange={(e) => handleRPAInputs(e, idx)}>
                                                                                <option value="Active">Active</option>
                                                                                <option value="Deactivated">Deactivated</option>
                                                                                <option value="Paused">Paused</option>
                                                                            </select> :
                                                                            <span>{rpa.status}</span>
                                                                        }
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        {toggleId == rpa.id ?
                                                                            <>
                                                                                <IconButton><CloseIcon className='rpa_close' onClick={() => toggleRPAEdit(rpa.id)} /></IconButton>
                                                                                <IconButton><DoneIcon className='rpa_done' onClick={() => editCustomRPA(rpa.id)} /></IconButton>
                                                                            </> :
                                                                            <img className='iconImg mx-2' src={editIcon} alt="edit" onClick={() => toggleRPAEdit(rpa.id)} />
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))) : (
                                                            <tr>
                                                                <td colSpan={9} className="noRecord text-center">
                                                                    No records found!
                                                                </td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 20]}
                                                component="div"
                                                count={RPAList?.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                nextIconButtonProps={{ "aria-label": "Next Page" }}
                                                onPageChange={handlePageChange}
                                                onRowsPerPageChange={handleRowsPerPage}
                                            />
                                        </div>
                                    </CardContent>
                                </Card>
                                <div className='mx-3 d-flex align-items-center justify-content-end'>
                                    <Button className='save' onClick={handleRPAPopup}><span>Add</span></Button>
                                    <AddRPA openRPA={openAddRPA} closeRPA={handleRPAPopup} client={clientDetails} RPAList={getCustomRPAList} />
                                </div>
                            </>
                        }
                    </CardContent>
                </Card>
            </div>

            <Card className="col-lg-12 col-sm-12 mt-3 mb-4 modalCard" variant="outlined">
                <CardHeader className='modalCardHead' title={<h5 className="mt-2 font20 text-white text-start font-face-futura">Practices</h5>} />
                <CardContent className="modalContent content-app">
                    <div className='table-responsive font-face-futura add-corps'>
                        <table className='table practise_tbl'>
                            {/* <table className='table practiceTable'> */}
                            <thead>
                                <tr>
                                    <th width="40%">Practice Name</th>
                                    <th># of Providers</th>
                                    <th>Provider Type</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {practices && practices
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((prac, idx) => (
                                        <tr key={idx}>
                                            <td width="40%">
                                                <input
                                                    type='text'
                                                    name="practiceName"
                                                    // className={this.state.selectedProviderIndex === prov.id ? 'providerName text-center' : 'disable providerName text-center'}
                                                    value={prac.practiceName}
                                                    disabled
                                                // disabled={this.state.selectedProviderIndex === prov.id ? false : true}
                                                // onChange={(e) => this.handleProviderInputs(e, prov.id)} 
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type='text'
                                                    name="providers"
                                                    // className={this.state.selectedProviderIndex === prov.id ? 'providerId text-center' : 'disable providerId text-center'}
                                                    value={prac.noOfProviders}
                                                    disabled
                                                // disabled={this.state.selectedProviderIndex === prov.id ? false : true}
                                                // onChange={(e) => this.handleProviderInputs(e, prov.id)} 
                                                />
                                            </td>
                                            <td>
                                                <select
                                                    // className={(roleDetails.roleId === 1 && this.state.selectedProviderIndex === prov.id) ? 'providerId text-center' : 'disable providerId text-center'}
                                                    onChange={(e) => handlePracticeData(e, idx)}
                                                    disabled={practiceIdx === prac.id ? false : true}
                                                    // disabled={(roleDetails.roleId === 1 && this.state.selectedProviderIndex === prov.id) ? false : true}
                                                    value={prac.providerType}
                                                    name="providerType"
                                                >
                                                    <option value="Scheduling">Scheduling</option>
                                                    <option value="Rendering">Rendering</option>
                                                </select>
                                            </td>
                                            <td>
                                                <select
                                                    // className={(roleDetails.roleId === 1 && this.state.selectedProviderIndex === prov.id) ? 'providerId text-center' : 'disable providerId text-center'}
                                                    onChange={(e) => handlePracticeData(e, idx)}
                                                    disabled={practiceIdx === prac.id ? false : true}
                                                    // disabled={(roleDetails.roleId === 1 && this.state.selectedProviderIndex === prov.id) ? false : true}
                                                    value={prac.status === true ? "Active" : prac.status === false ? "Not Active" : ''}
                                                    name="status"
                                                >
                                                    <option value="Active">Active</option>
                                                    <option value="Not Active">Not Active</option>
                                                </select>
                                            </td>
                                            <td>
                                                {practiceIdx === prac.id ?
                                                    <IconButton>
                                                        <DoneIcon style={{ color: '#7BBC9B' }} onClick={() => editClientPractice(prac.id)} />
                                                    </IconButton> :
                                                    <IconButton onClick={() => toggleEdit(prac.id)}>
                                                        <img className='mx-1' alt="edit" src={editIcon} />
                                                    </IconButton>}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>


                        </table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={practices?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                            nextIconButtonProps={{ "aria-label": "Next Page" }}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPage}
                        />
                    </div>
                    <div className='d-flex align-items-center justify-content-end'>
                        <Button className='save mt-3 mx-2' onClick={handleAddPracticeModal}><span>Add</span></Button>
                    </div>

                    <AddPracticeModal openModal={openAddPractice} closeModal={handleAddPracticeModal} clientData={clientDetails} />
                </CardContent>
            </Card>
        </section>
    )
}

export default EditClient;