import { Box as MaterialBox, Modal } from "@mui/material";
import { QuestionMark } from "../../assets/icons";
import { ROButton } from "../RoButton/index";
import { mapIconToAlertType, RoAlertTypes } from "./constants";
import "./style.css";

const alertStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "white",
  border: "1px solid white",
  boxShadow: 24,
  height: "auto",
  borderRadius: "15px",
  padding: "30px",
  outline: 'none !important'
};

export const RoAlert = ({
  show,
  handleClose,
  markAsReviewOnly,
  softDelete,
  title = "",
  content,
  closeBtnText = "nevermind",
  btnText1 = "Mark Only",
  btnText2 = "Soft Delete",
  type = "info",
}) => {
  const alertTypes = Object.values(RoAlertTypes);
  if (!alertTypes.includes(type)) {
    console.error(
      `Please provide a valid type of alert. It must be one from - ${alertTypes.join(
        " | "
      )}`
    );
  }

  return (
    <Modal open={show} onClose={handleClose}>
      <MaterialBox sx={{ ...alertStyle }}>
        <div className="alert-icon-container">
          <img
            className="alert-icon"
            src={mapIconToAlertType[type] || QuestionMark}
          />
        </div>
        <div className="mt-4"></div>
        <h1 className="alert-heading mt-5 text-uppercase">{title}</h1>
        {content}
        <div className="pt-2 alert-footer">
          {/* <div className="text-center alert-footer-text">
            {"Would you like to continue?"}
          </div>
          <br /> */}
          <div className="row text-center">
            <>
              <div className="col-4">
                <ROButton
                  className="w-100"
                  onClick={handleClose}
                  type="danger"
                  fontSize={23}
                >
                  {closeBtnText}
                </ROButton>
              </div>
              <div className="col-4">
                <ROButton
                  className="w-100"
                  fontSize={23}
                  onClick={markAsReviewOnly}
                  type="info"
                >
                  {btnText1}
                </ROButton>
              </div>
              <div className="col-4">
                <ROButton
                  className="w-100"
                  fontSize={23}
                  onClick={softDelete}
                  type="info"
                >
                  {btnText2}
                </ROButton>
              </div>
            </>
          </div>
        </div>
      </MaterialBox>
    </Modal>
  );
};
