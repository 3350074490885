import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Card, CardContent, CardHeader, TablePagination } from '@mui/material';
import editIcon from '../../../assets/icons/settingEdit.svg';
import EditInvoice from './EditInvoice';
import { getInvoice, updateInvoiceStatus } from '../adminAction';
import { getMasterSettings } from '../../Settings/settingsActions';

export const Invoice = () => {
    const dispatch = useDispatch();
    const [invoiceData, setInvoiceData] = useState([]);
    const [invoiceDataCopy, setInvoiceDataCopy] = useState([]);
    const [date, setDate] = useState((new Date(Date.now())).toLocaleDateString('en-US'));
    const [editInvoiceModal, setEditInvoiceModal] = useState(false);
    const [invoice, setInvoice] = useState({});
    const [filterVal, setFilterVal] = useState('Not Invoiced');
    const [searchKey, setsearchKey] = useState('client_name');
    const [searchVal, setsearchVal] = useState('');
    const [placeholder, setPlaceholder] = useState('');
    const [optionSelector, setOptionSelector] = useState(false);

    // pagination constants
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const searchItems = [
        {key: 'clientName', value: 'Client Name'},
        {key: 'client_type', value: 'Client Type'},
        {key: 'invoiceDate', value: 'Invoice Date'},
        {key: 'id', value: 'Invoice Number'}
    ]

    // function to handle page change
    const handlePageChange = (event, currentPage) => {
      setPage(currentPage);
    };
  
    // function to handle rows per page
    const handleRowsPerPage = (event) => {
      let rowspage = parseInt(event.target.value, 10);
      setRowsPerPage(rowspage);
      setPage(0)
    };

    const handleFilter = (e) => {
        const {value} = e.target;
        setFilterVal(value);

        let filterResult = invoiceDataCopy.filter((item) =>{
            let status = item.status === true ? 'Invoiced' : 'Not Invoiced';
            let res = status.toLowerCase() === value.toLowerCase() ? true : false;
            return res;
    })
        setInvoiceData(filterResult);
    }

    // logic to search encounters by dropdown selection
    const handleSelectChange = (e) => {
        let res = searchItems.map((item) => {
            if(item.key === e.target.value){
                return setPlaceholder(item.value);
            }
        })
        setOptionSelector(false);
        setsearchKey(e.target.value)
    }

    const handleInputChange = (e) => {
        let value =  e.target.value;
        setsearchVal(value);
        let searchResult = invoiceDataCopy.map((val) => {
            // console.log(val)
            return {
                ...val,
                client_type: val.client?.noOfPrac > 1 ? 'Multi Practice' : 'Single Practice'
            }
        }).filter((item) => {
            // console.log(item)
            if(item[searchKey].toString().toUpperCase().indexOf(value.toUpperCase()) !== -1){
                return item;
            }
        })
        
        let final_result = searchResult.map((type) => {
            return {
                ...type,
                client_type: type.client?.noOfPrac > 1 ? 'Multi Practice' : 'Single Practice'
            }
        })
        setInvoiceData(final_result);
    }

    const selectHandler = (e) => {
        setOptionSelector(!optionSelector)
    }

    // function to handle edit invoice modal
    const handleEditInvoice = (invoice) => {
        console.log(invoice)
        setInvoice(invoice);
        setEditInvoiceModal(!editInvoiceModal);
    }

    // function to get invoice details
    useEffect(() => {
        // let modDate = date.split('/').join('-');
        let modDate = date.replace(/\//g, '-');
        // console.log(modDate)
        dispatch(getInvoice(modDate)).then((res) => {
            // console.log(res.payload.data.data);
            let data = res.payload.data.data;
            let notInvoiced = data.filter(x => x.status === false)
            setInvoiceData(notInvoiced);
            setInvoiceDataCopy(data);
            setFilterVal("Not Invoiced");
        })
    }, [0])

    // function to fetch master invoice settings
    const getInvoices = () => {
        let modDate = date.replace(/\//g, '-');
        dispatch(getInvoice(modDate)).then((res) => {
            let data = res.payload.data.data;
            let notInvoiced = data.filter(x => x.status === false)
            setInvoiceData(notInvoiced);
            setInvoiceDataCopy(data);
            setFilterVal("Not Invoiced");
        })
    }

    // function to handle invoice status
    // const handleInvoiceStatus = (e, id) => {
    //     const { name, value } = e.target;
    //     setStatus({[name]: value});

    //     dispatch(updateInvoiceStatus({id, status})).then((res) => {
    //         let status = res.payload.data;
    //         console.log(status);
    //         getInvoices();
    //     })
    // }
    const handleInvoiceStatus = (e, id, index) => {
        let {value} = e.target;
        let invoiceDataCopy = [...invoiceData];
        invoiceDataCopy[index].status = value;
        setInvoiceData(invoiceDataCopy)

        let status = invoiceData[index].status;
        console.log(status)

        dispatch(updateInvoiceStatus({id, status})).then((res) => {
            console.log(res);
            // let status = res.payload.data;
            // console.log(status);
            getInvoices();
        })
    }

    return (
        <section className='client_section'>
            <div className='clientflex'>
                <div className='col-lg-6 col-sm-6'>
                    <div className='clients_col font-face-futura'>
                        <span>INVOICING</span>
                    </div>
                </div>
                <div className='col-lg-6 col-sm-6'>
                    <div className='filter_col font-face-futura'>
                        <span className='mx-3'>Filter By:</span>
                        <select className='filter_drop_nav text-center' name="filter" value={filterVal ? filterVal : 'Not Invoiced'} onChange={handleFilter}>
                            <option value="not invoiced">Not Invoiced</option>
                            <option value="invoiced">Invoiced</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <div className='col-lg-12 col-sm-12'>
                    <div className='search_by'>
                        <div className='mx-3 setting-filter'>
                            <select 
                            className={optionSelector === true ? 'sticky-selection2 show-selector' : 'sticky-selection2 none-selector'} 
                            onChange={handleSelectChange} multiple>
                            {searchItems.map((item, key) => (<>
                                <option className='p-1 option text-center font-face-futura-footer' key={key} value={item.key}>{item.value}</option>
                                </>))}
                            </select>
                            <FilterListIcon style={{ margin: '4px' }} onClick={selectHandler} />
                        </div>
                        <div>
                            <input type='text' value={searchVal}
                            className='setting-search font-face-futura' 
                            placeholder={placeholder ? 'Search by' + ' ' + placeholder + '...' : 'Search by Client Name...'}
                            onChange={handleInputChange} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <Card className='client_card'>
                    <CardHeader className='client_card_head' title={<p className='pt-3 card_head_txt'>Clients List</p>} />
                    <CardContent>
                        <div className='table-responsive font-face-futura'>
                            <table className='table client-table'>
                                <thead className='tableHead text-white'>
                                    <tr>
                                        <th>Invoice #</th>
                                        <th>Client Name</th>
                                        <th>Client Type</th>
                                        <th>Transactions</th>
                                        <th>Eligibility</th>
                                        <th>Invoice Date</th>
                                        <th>Invoice Amount</th>
                                        <th>Eligibility Cost</th>
                                        <th>Invoiced?</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoiceData?.length > 0 ? 
                                    (invoiceData?.slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    ).map((invoice, idx) => (
                                    <tr>
                                        <td>{invoice?.id}</td>
                                        <td>{invoice?.clientName}</td>
                                        <td>{invoice?.noOfPractice > 1 ? 'Multi-Practice' : 'Single-Practice'}</td>
                                        <td>{invoice?.transactions}</td>
                                        <td>{invoice?.eligibility}</td>
                                        <td>{invoice?.invoiceDate}</td>
                                        <td>${invoice?.invoiceAmount}</td>
                                        <td>${invoice?.eligibilityCost}</td>
                                        <td>
                                            <select className='filter_drop text-center' name="status"
                                            value={invoice?.status} 
                                            onChange={(e) => handleInvoiceStatus(e, invoice?.id, idx)}>
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </td>
                                        <td>
                                            <img className='iconImg mx-2' src={editIcon} alt="edit" onClick={() => handleEditInvoice(invoice)} />
                                        </td>
                                    </tr>
                                    ))) : (
                                        <tr>
                                          <td colSpan={10} className="noRecord text-center">
                                            No records found!
                                          </td>
                                        </tr>
                                    )}
                                </tbody>
                                <EditInvoice openEditInvoice={editInvoiceModal} closeEditInvoice={handleEditInvoice} invoice={invoice} getInvoice={getInvoices} />
                            </table>
                            <TablePagination
                            rowsPerPageOptions={[5, 10, 20]}
                            component="div"
                            count={invoiceData?.length ? invoiceData?.length : 0 }
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{ "aria-label": "Previous Page" }}
                            nextIconButtonProps={{ "aria-label": "Next Page" }}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPage}
                            />
                        </div>
                    </CardContent>
                </Card>
            </div>
        </section>
    )
}