import { toast } from 'react-toastify';

// export const onSuccess = (message) => {
//     toast.success(`${message}`, {
//             autoClose: 2000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: 'coloured'
//         }
//     );
// }
// export const onError = (message) => {
//     toast.error(`${message}`, {
//             autoClose: 2000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: 'coloured'
//         }
//     );
// }
// export const onInfo = (message) => {
//     toast.info(`${message}`, {
//             autoClose: 2000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: 'coloured'
//         }
//     );
// }
// export const onWarning = (message) => {
//     toast.warning(`${message}`, {
//             autoClose: 2000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: 'coloured'
//         }
//     );
// }

const AlertMessages = (message, type, time) => {
    console.log(type)
    const toastCSS = {
        autoClose: time,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
    }
    switch(type){
        case 'Success':
            toast.success(`${message}`, toastCSS)
            break;
        case 'Error':
            toast.error(`${message}`, toastCSS)
            break;
        case 'Info':
            toast.info(`${message}`, toastCSS)
            break;
        case 'Warning':
            toast.warning(`${message}`, toastCSS)
            break;
        default:
            break;
    }
}

export default AlertMessages;