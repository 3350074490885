import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authReducer from '../../src/features/Auth/Login/authSlice';
import clientReducer from '../../src/features/AdminConsole/Clients/clientSlice';
import settingReducer from '../features/Settings/settingsSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    client: clientReducer,
    settings: settingReducer
  },
});
