import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle } from '@mui/material';
import editIcon from '../../../assets/icons/settingEdit.svg';
import DoneIcon from '@mui/icons-material/Done';
import { editInvoice } from '../adminAction';

const EditInvoice = (props) => {
    const dispatch = useDispatch();
    const [invoice, setInvoice] = useState({});
    const [clientInfo, setClientInfo] = useState({})
    const [disableFields, setDisableFields] = useState("");
    const [invoiceTotal, setInvoiceTotal] = useState();

    // console.log(props?.invoice)

    // function handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInvoice({ [name]: value });
    }
    const handleClientInputChange = (e) => {
        const { name, value } = e.target;
        setClientInfo({ ...clientInfo, [name]: value });
    }

    // function to toggle edit
    const toggleEdit = (e, toggleName) => {
        const { name } = e.target;
        if (toggleName === name) {
            setDisableFields(toggleName);
        } else {
            setDisableFields("")
        }
    }

    useEffect(() => {
        setInvoice(props?.invoice)
        setClientInfo({
            defaultPrice: props?.invoice?.price_per_transaction,
            defaultCharge: props?.invoice?.defaultChargePerProvider,
            minimumTransaction: props?.invoice?.minimum_transaction,
            providers: props?.invoice?.activeProviders
        })
    }, [props]);

    // function to edit invoice
    const editInvoiceData = () => {
        console.log(clientInfo)
        let data = {
            id: invoice.id,
            providers: clientInfo?.providers ? clientInfo?.providers : invoice?.activeProviders,
            price_per_transaction: clientInfo?.defaultPrice ? clientInfo?.defaultPrice : invoice?.price_per_transaction,
            defaultChargePerProvider: clientInfo?.defaultCharge ? clientInfo?.defaultCharge : invoice?.defaultChargePerProvider,
            minimum_transaction: Number(clientInfo?.minimumTransaction) ? Number(clientInfo?.minimumTransaction) : Number(invoice?.minimum_transaction)
        }
        console.log(data)
        dispatch(editInvoice(data)).then((res) => {
            console.log(res)
            props.closeEditInvoice();
            props.getInvoice();
        })
    }

    useEffect(() => {
        let defaultCharge = typeof clientInfo?.defaultCharge === "boolean" ? clientInfo?.defaultCharge : clientInfo?.defaultCharge === "true" ? true : false;
        let invoiceAmount;
        if(defaultCharge === true){
            invoiceAmount = transactionTotal < minimumTotal ? minimumTotal : transactionTotal
        }else{
            invoiceAmount = transactionTotal;
        }
        setInvoiceTotal(invoiceAmount);
      }, [clientInfo, invoice]);

    let transactionTotal = invoice?.transactions * Number(clientInfo?.defaultPrice);
    let minimumTotal = clientInfo?.minimumTransaction * Number(clientInfo?.defaultPrice) * clientInfo?.providers;

    return (
        <>
            {props?.openEditInvoice &&
                <Dialog fullWidth
                    open={props.openEditInvoice}
                    // onClose={props.closeEditInvoice}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <div className='modalTitle'>
                        <DialogTitle id="alert-dialog-title">
                            <span className='font-face-futura'>{"Edit Invoice"}</span>
                        </DialogTitle>
                    </div>
                    <DialogContent className='pos-relative'>
                        <div className='mt-2'>
                            <Card className='modalCard mb-2' >
                                <CardHeader className='modalCardHead' title={<h5 className="my-2 text-white font-face-futura">Info</h5>} />
                                <CardContent className='modalContent'>
                                    <div className="row mx-0">
                                        <div className='col-lg-12 col-sm-12 mb-2'>
                                            <label htmlFor="clientName"><strong className='strongTxt'>Client Name:</strong></label>
                                            <input className='inputField disabled' disabled value={invoice?.clientName}
                                                name="practiceName" />
                                            {/* <p className="text-danger" style={style}>{errors.practiceName}</p> */}
                                        </div>
                                        <div className='my-1 col-lg-12 col-sm-12 mb-2'>
                                            <label htmlFor="clientKey"><strong className='strongTxt'>Client Key:</strong></label>
                                            <input className='inputField disabled' disabled value={invoice?.clientKey}
                                                name="clientKey" />
                                            {/* <p className="text-danger" style={style}>{errors.practiceName}</p> */}
                                        </div>
                                        <div className='my-1 col-lg-6 col-sm-6 mb-2'>
                                            <label htmlFor="firstName"><strong className='strongTxt'>Contact First Name:</strong></label>
                                            <input className='inputField disabled' disabled value={invoice?.firstName}
                                                name="firstName" />
                                            {/* <p className="text-danger" style={style}>{errors.practiceAcronym}</p> */}
                                        </div>
                                        <div className='my-1 col-lg-6 col-sm-6 mb-2'>
                                            <label htmlFor="lastName"><strong className='strongTxt'>Contact Last Name:</strong></label>
                                            <input className='inputField disabled' disabled value={invoice?.lastName}
                                                name="lastName" />
                                            {/* <p className="text-danger" style={style}>{errors.practiceAcronym}</p> */}
                                        </div>
                                        <div className='my-1 col-lg-12 col-sm-12 mb-2'>
                                            <label htmlFor="contactPhone"><strong className='strongTxt'>Contact Phone:</strong></label>
                                            <input className='inputField disabled' disabled value={invoice?.phone}
                                                name="practiceAcronym" />
                                            {/* <p className="text-danger" style={style}>{errors.practiceAcronym}</p> */}
                                        </div>
                                        <div className='my-1 col-lg-12 col-sm-12 mb-2'>
                                            <label htmlFor="contactEmail"><strong className='strongTxt'>Contact Email:</strong></label>
                                            <input className='inputField disabled' disabled value={invoice?.email}
                                                name="practiceEmail" />
                                            {/* <p className="text-danger" style={style}>{errors.practiceEmail}</p> */}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card className='modalCard' >
                                <CardHeader className='modalCardHead' title={<h5 className="my-2 text-white font-face-futura">Invoice</h5>} />
                                <CardContent className='modalContent'>
                                    <div className="row mx-0">
                                        <div className='col-lg-6 col-sm-6 mb-2'>
                                            <label htmlFor="invoice"><strong className='strongTxt'>Invoice #:</strong></label>
                                            <input className='inputField disabled' disabled value={invoice?.id}
                                                name="invoice" />
                                            {/* <p className="text-danger" style={style}>{errors.practiceAcronym}</p> */}
                                        </div>

                                        <div className='col-lg-6 col-sm-6  mb-2'>
                                            <label htmlFor="invoiceDate"><strong className='strongTxt'>Invoice Date:</strong></label>
                                            <input className='inputField disabled' disabled value={invoice?.invoiceDate}
                                                name="invoiceDate" />
                                            {/* <p className="text-danger" style={style}>{errors.practiceAcronym}</p> */}
                                        </div>

                                        <div className='row'>
                                            <div className='my-2 col-lg-12 col-sm-12 position-relative'>
                                                <label htmlFor="defaultPrice"><strong className='strongTxt'>Price Per Transaction:</strong></label>
                                                <input className={disableFields === "defaultPrice" ? 'inputField' : 'inputField disabled'}
                                                    disabled={disableFields === "defaultPrice" ? false : true}
                                                    name='defaultPrice' value={clientInfo?.defaultPrice} onChange={handleClientInputChange} />
                                                {disableFields === "defaultPrice" ?
                                                    <DoneIcon className='done' onClick={(e) => toggleEdit(e, "defaultPrice")} /> :
                                                    <img src={editIcon} alt="edit" className='mt-3 editIT' name="defaultPrice" onClick={(e) => toggleEdit(e, "defaultPrice")} />
                                                }
                                                {/* <p className="text-danger" style={style}>{errors.practiceAcronym}</p> */}
                                            </div>
                                        </div>


                                        <div className='col-lg-6 col-sm-6  mb-2'>
                                            <label htmlFor="transaction"><strong className='strongTxt'>Transactions:</strong></label>
                                            <input className='inputField disabled' disabled value={invoice?.transactions}
                                                name="transaction" />
                                            {/* <p className="text-danger" style={style}>{errors.practiceAcronym}</p> */}
                                        </div>

                                        <div className='col-lg-6 col-sm-6  mb-2'>
                                            <label htmlFor="eligibility"><strong className='strongTxt'>Eligibility:</strong></label>
                                            <input className='inputField disabled' disabled value={invoice?.eligibility}
                                                name="eligibility" />
                                            {/* <p className="text-danger" style={style}>{errors.practiceAcronym}</p> */}
                                        </div>

                                        <div className='col-lg-12 col-sm-12  mb-2'>
                                            <label htmlFor="total"><strong className='strongTxt'>Transaction Total:</strong></label>
                                            <input className='inputField disabled' disabled
                                                // value={"$" + invoice?.transactionTotal}
                                                value={"$" + transactionTotal}
                                                name="total" />
                                            {/* <p className="text-danger" style={style}>{errors.practiceName}</p> */}
                                        </div>

                                        <div className='row'>
                                            <div className='my-2 col-lg-12 col-sm-12 position-relative'>
                                                <label htmlFor="defaultCharge"><strong>Default Charge Per Active Provider?</strong></label>
                                                <select className={disableFields === "defaultCharge" ? 'modal_select' : 'disabled modal_select'}
                                                    disabled={disableFields === "defaultCharge" ? false : true}
                                                    name='defaultCharge' value={clientInfo?.defaultCharge} onChange={handleClientInputChange}>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </select>
                                                {disableFields === "defaultCharge" ?
                                                    <DoneIcon className='done' onClick={(e) => toggleEdit(e, "defaultCharge")} /> :
                                                    <img src={editIcon} alt="edit" className='mt-3 editIT' name="defaultCharge" onClick={(e) => toggleEdit(e, "defaultCharge")} />
                                                }
                                                <small className='smallTxt'>Clients will not be able to run claims for providers that are not active in their practice settings</small>
                                                {/* <p className="text-danger" style={style}>{errors.practiceEmail}</p> */}
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='my-2 col-lg-6 col-sm-12 position-relative'>
                                                <label htmlFor="minimumTransaction"><strong>Minimum Transaction Per Provider:</strong></label>
                                                <input className={disableFields === "minTrans" ? 'inputField' : 'inputField disabled'}
                                                    disabled={disableFields === "minTrans" ? false : true}
                                                    name="minimumTransaction" value={clientInfo?.minimumTransaction} onChange={handleClientInputChange} />
                                                {disableFields === "minTrans" ?
                                                    <DoneIcon className='done' onClick={(e) => toggleEdit(e, "minTrans")} /> :
                                                    <img src={editIcon} alt="edit" className='mt-3 editIT' name="minTrans" onClick={(e) => toggleEdit(e, "minTrans")} />
                                                }
                                                {/* <p className="text-danger" style={style}>{errors.practicePassword}</p> */}
                                            </div>
                                            <div className='my-2 ps-sm-4 col-lg-6 col-sm-12 position-relative'>
                                                <label htmlFor="providers"><strong className='strongTxt'>Number of Providers:</strong></label>
                                                <input className={disableFields === "providers" ? 'inputField' : 'inputField disabled'}
                                                    disabled={disableFields === "providers" ? false : true}
                                                    name="providers" value={clientInfo?.providers} onChange={handleClientInputChange} />

                                                {disableFields === "providers" ?
                                                    <DoneIcon className='done' onClick={(e) => toggleEdit(e, "providers")} /> :
                                                    <img src={editIcon} alt="edit" className='mt-3 editIT' name="providers" onClick={(e) => toggleEdit(e, "providers")} />
                                                }
                                                {/* <p className="text-danger" style={style}>{errors.practicePassword}</p> */}
                                            </div>
                                        </div>

                                        <div className='col-lg-12 col-sm-12  mb-2'>
                                            <label htmlFor="minimum"><strong className='strongTxt'>Minimum Total:</strong></label>
                                            <input className='inputField disabled' disabled
                                                // value={"$" + invoice?.minTotalAmount}
                                                value={"$" + minimumTotal}
                                                name="minimum" />
                                            {/* <p className="text-danger" style={style}>{errors.practiceName}</p> */}
                                        </div>

                                        <div className='col-lg-12 col-sm-12 position-relative mb-2'>
                                            <label htmlFor="invoiceTotal"><strong className='strongTxt'>Invoice Total:</strong></label>
                                            <input className='inputField disabled' disabled name='invoiceTotal' value={"$" + invoiceTotal} />
                                            <small className='smallTxt'>If the price per transaction is not greater than the minimum then the minimum will show as the invoice total</small>
                                            {/* <p className="text-danger" style={style}>{errors.practiceEmail}</p> */}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            <footer className='set-btn'>
                                <div className='actionButton'>
                                    <Button fullWidth
                                        className="mt-4 mx-2 nevermindBtn"
                                        variant="outlined" onClick={props.closeEditInvoice}>
                                        <span className="btnSpan">Nevermind</span>
                                    </Button>
                                    <Button fullWidth
                                        className="mt-4 mx-2 addClientBtn"
                                        variant="outlined" disabled={disableFields !== ""}
                                        onClick={editInvoiceData}>
                                        <span className="btnSpan">Save</span>
                                    </Button>
                                </div>
                            </footer>
                        </div>
                    </DialogContent>
                </Dialog>
            }
        </>
    )
}

export default EditInvoice;