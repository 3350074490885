import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Tab, Card, CardHeader, CardContent, CardActions, Button, TablePagination, IconButton, Tooltip } from '@mui/material';
import editIcon from '../../assets/icons/settingEdit.svg';
import deleteIcon from '../../assets/icons/settingDelete.svg';
import resendInvite from '../../assets/icons/resendInvite.svg';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { deleteUser, downloadCodes, editMasterSettings, getMasterSettings, getUploadedCodes, getUsers, uploadCodes, getGlobalRPA, updateRPA } from './settingsActions';
import Papa from 'papaparse';
import AddUser from './AddUser';
import { RoAlert } from '../../layouts/RoAlert';
import EditUser from './EditUser';
import * as XLSX from 'xlsx';
import { CSVLink } from "react-csv";
import hidePassword from '../../assets/icons/hidePassword.svg';
import showPassword from '../../assets/icons/showPassword.svg';

export const UploadCard = ({ type, lastUpdated, count, updatedBy, getCodes }) => {
    const dispatch = useDispatch();
    const [codeFile, setCodeFile] = useState('');
    const [codeList, setCodeList] = useState([]);
    const [codes, setCodes] = useState([]);
    const [exportcodes, setExportCodes] = useState(false);
    const excelRef = useRef();

    let headers = [];

    switch (type) {
        case 'Procedure Codes':
            headers.push(
                { label: "Procedure Codes", key: "procedureCodes" },
                { label: "Disease Name", key: "diseaseName" })
            break;
        case 'Diagnosis Codes':
            headers.push(
                { label: "Diagnosis Codes", key: "diagnosisCodes" },
                { label: "Disease Name", key: "diseaseName" })
            break;
        case 'Modifier Codes':
            headers.push({ label: "Modifier Codes", key: "modifierCode" })
            break;
        case 'Place of Service Codes':
            headers.push({ label: "Place of Service Codes", key: "placeOfServiceCode" })
            break;
        case 'Payer Name':
            headers.push(
                { label: "Payer Name", key: "payerName" },
                { label: "States", key: "states" },
                { label: "PayerId", key: "payerId" },
                { label: "Status", key: "status" },
            )
            break;
        case 'Legal Entity Name':
            headers.push(
                { label: "Legal Entity Name", key: "legalEntityName" },
                { label: "Marketing Name", key: "marketingName" },
                { label: "Contract Number", key: "contractNumber" },
                { label: "Effective Date", key: "effectiveDate" },
                { label: "Contact Number", key: "contactNumber" })
            break;
        default:
            break;
    }

    // function to upload codes
    const uploadCode = (e, type) => {
        let { file } = codeFile;
        // setCodeType(type)
        console.log(e.target, type);
        file = e.target.files[0];
        console.log(file);
        if (file) {
            Papa.parse(file, {
                complete: (result) => {
                    console.log(result)
                    if (type === result.data[0][0]) {
                        result.data.shift();
                        result.data.pop();
                        let codeData = [];
                        result.data.map(item => {
                            switch (type) {
                                case 'Procedure Codes':
                                case 'Diagnosis Codes':
                                    codeData.push({ code: item[0], name: item[1] });
                                    break;
                                case 'Payer Name':
                                    codeData.push({ payerName: item[0], states: item[1], payerId: item[2], status: item[3] });
                                    break;
                                case 'Legal Entity Name':
                                    codeData.push({ legal: item[0], market: item[1], contract: item[2], date: item[3], contact: item[4] });
                                    break;
                                default:
                                    codeData.push(item[0]);
                                    break;
                            }
                        })
                        setCodeList(codeData);
                        handleCodesUpload(type, codeData);
                    } else {
                        console.error("Export valid group type")
                    }
                }
            })
        }
    }

    const handleCodesUpload = (codeType, codes) => {
        let data = {
            type: codeType,
            codes: codes
        }
        console.log(data);
        dispatch(uploadCodes(data)).then((res) => {
            console.log(res)
            getCodes();
        })
    }

    const downloadCode = async (e, type) => {
        let data = {
            type: type
        }
        console.log("type", data);
        await dispatch(downloadCodes(data)).then((res) => {
            let codes = res.payload.data.result;
            let newCodes = codes.map(({ id, ...rest }) => rest);
            setCodes(newCodes);
            setExportCodes(true)
            // const worksheet = XLSX.utils.json_to_sheet(newCodes);
            // const workbook = XLSX.utils.book_new();
            // XLSX.utils.book_append_sheet(workbook, worksheet, "Codes");
            // XLSX.writeFile(workbook, `${type}.csv`);
            // const worksheet = XLSX.utils.json_to_sheet(newCodes);
            // const worksheetCSV = XLSX.utils.sheet_to_csv(worksheet);
            // const workbook = XLSX.utils.book_new();
            // let blob = new Blob(["\ufeff", worksheetCSV]);
            // let fileBlob = new File([blob], "Codes");
            // XLSX.utils.book_append_sheet(workbook, fileBlob, "Codes");
            // XLSX.writeFile(workbook, `${type}.csv`);
        })

    };

    useEffect(() => {
        if (codes.length > 0) {
            excelRef.current.link.click();
            setExportCodes(false);
            setCodes([]);
        }
    }, [exportcodes])

    return (
        <>
            <div className='dataDiv'>
                <Card className='my-2 settingCard'>
                    <CardHeader
                        className='settingCardHead'
                        title={<h5 className="my-2 text-white">{type === 'Payer Name' ? 'Trizetto Payers' : type === 'Legal Entity Name' ? 'Advantage Plans' : type}</h5>} />
                    <CardContent className='settingContent padding0'>
                        <div className='divCard'>
                            <p>
                                <strong>Last Updated: </strong>
                                <span>{lastUpdated}</span>
                            </p>
                            <p>
                                <strong>{type === 'Payer Name' ? 'Trizetto Payers' : type === 'Legal Entity Name' ? 'Advantage Plans' : type} Count: </strong>
                                <span>{count}</span>
                            </p>
                            <p>
                                <strong>Updated By: </strong>
                                <span>{updatedBy}</span>
                            </p>
                        </div>
                    </CardContent>
                    <CardActions>
                        <CSVLink data={codes} disabled={true} headers={headers} ref={excelRef} className='hiddenExcel' target='_blank' filename={`${type}.csv`}></CSVLink>
                        <Button className='mx-2 uploadNew' variant='contained' onClick={(e) => downloadCode(e, type)}>
                            <span className='font-face-futura'>Download</span>
                        </Button>
                        <label className='mx-2 uploadNew'>
                            <input type="file" style={{ display: "none" }} accept='.csv' onChange={(e) => uploadCode(e, type)} />
                            <span>UPLOAD NEW</span>
                        </label>
                    </CardActions>
                </Card>
            </div>
        </>
    )
}

export const Settings = () => {
    const dispatch = useDispatch();
    const [tabValue, setTabValue] = useState(0);
    const [panelTabVal, setPanelTabVal] = useState(0);
    const [settingsInfo, setSettingsInfo] = useState({});
    const [disableFields, setDisableFields] = useState("");
    const [codesData, setCodesData] = useState([
        { type: 'Procedure Codes', lastUpdated: null, procedureCodeCount: 0, updatedBy: '' },
        { type: 'Diagnosis Codes', lastUpdated: null, diagnosisCodeCount: 0, updatedBy: '' },
        { type: 'Modifier Codes', lastUpdated: null, modifierCodeCount: 0, updatedBy: '' },
        { type: 'Place of Service Codes', lastUpdated: null, placeOfServiceCodeCount: 0, updatedBy: '' },
        { type: 'Trizetto Payers', lastUpdated: null, trizettoPayersCount: 0, updatedBy: '' },
        { type: 'Advantage Plans', lastUpdated: null, advantagePlansCount: 0, updatedBy: '' }
    ]);
    const [addUserPopup, setAddUserPopup] = useState(false);
    const [editUserPopup, setEditUserPopup] = useState(false);
    const [users, setUsers] = useState([]);
    const [usersCopy, setUsersCopy] = useState([]);
    const [userData, setUserData] = useState({});
    const [openResendPopup, setOpenResendPopup] = useState(false);
    const [filterVal, setFilterVal] = useState('All');
    const [searchVal, setSearchVal] = useState('');
    const [RPAList, setRPAList] = useState([]);
    const [toggleId, setToggleId] = useState(null);
    const [tempPass, setTempPass] = useState('password');

    // pagination constants
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // function to handle page change
    const handlePageChange = (event, currentPage) => {
        setPage(currentPage);
    };

    // function to handle rows per page
    const handleRowsPerPage = (event) => {
        let rowspage = parseInt(event.target.value, 10);
        setRowsPerPage(rowspage);
        setPage(0);
    };

    // function to toggle password visibility
    const showTempPass = (rpaId) => {
        RPAList.filter((rpa, idx) => {
            if(rpaId === idx){
                if(tempPass === 'password'){
                    setTempPass('input');
                }else{
                    setTempPass('password');
                }
            }
        })
    }

    // function to handle edit rpa data
    const toggleRPAEdit = (rpaId) => {
        if (rpaId === toggleId) {
            setToggleId(null)
        } else {
            setToggleId(rpaId)
        }
    }

    // funtion to handle input change
    const handleRPAInputs = (e, index) => {
        const { name, value } = e.target;
        console.log(name, value)
        setRPAList(RPAList => RPAList.map((item, idx) => index === idx
            ? {
                ...item,
                [name]: value,
            }
            : item
        ));
        // let rpaList = [];
        // RPAList.map(rpa => {
        //     let newrpa = { ...rpa }
        //     if (rpa.id === rpaId) {
        //         console.log(name, value)
        //         if (name === "rpaName") {
        //             newrpa.name = value
        //         }
        //         if (name === "rpaLink") {
        //             newrpa.link = value;
        //         }
        //         if (name === "rpaStatus") {
        //             newrpa.status = value;
        //         }
        //     }
        //     rpaList.push(newrpa);
        // })
        // setRPAList(rpaList);
    }

    // function to update RPA data
    const editRPAData = (rpaId) => {
        let id, rpa_id, name, link, status;
        RPAList.map(rpa => {
            if(rpa.id === rpaId){
                id = rpa.id;
                rpa_id = rpa.rpa_id;
                name = rpa.name;
                link = rpa.link;
                status = rpa.status
            }
            return rpa;
        })
        let body_data = {
            id: id,
            rpa_id: rpa_id,
            name: name,
            link: link,
            status: status
        }
        dispatch(updateRPA(body_data)).then(res => {
            console.log(res);
            toggleRPAEdit();
        })
    }

    const filterItems = [
        { key: 'all', value: 'All' },
        { key: 'invite_sent', value: 'Invite Sent' },
        { key: 'invite_expired', value: 'Invite Expired' },
        { key: 'inactive', value: 'Inactive' }
    ]

    // function to filter users
    const handleFilter = (e) => {
        const { value } = e.target;
        setFilterVal(value);
        let filterResult;
        switch (value) {
            case 'All':
                // filterResult = usersCopy;
                filterResult = usersCopy.filter((item) => (item.status === true && item.active === true) || (item.status === true && item.active === false && item.activeExpires > Date.now()));
                break;
            case 'Invite Sent':
                filterResult = usersCopy.filter((item) => item.status === true && item.active === false && item.activeExpires > Date.now());
                break;
            case 'Invite Expired':
                filterResult = usersCopy.filter((item) => item.status === true && item.active === false && item.activeExpires < Date.now());
                break;
            case 'Inactive':
                filterResult = usersCopy.filter((item) => (item.status === false) || (item.status === true && item.active === false && item.activeExpires < Date.now()));
                break;
            default:
                filterResult = usersCopy.filter((item) => (item.status === true && item.active === true) || (item.status === true && item.active === false && item.activeExpires > Date.now()));
        }
        // console.log(filterResult);
        setUsers(filterResult);
    }

    // function to search users
    const handleSearch = (e) => {
        const { value } = e.target;
        setSearchVal(value);
        let searchResult = usersCopy.filter((item) => {
            if ((item.first_name + " " + item.last_name).toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                return item;
            }
        })
        setUsers(searchResult);
    }

    // function to open resend pop up
    const handleResendPopup = () => {
        setOpenResendPopup(true);
    };

    // function to handle tab value
    const handleTabs = (e, tabValue) => {
        setTabValue(tabValue);
    }

    // function to handle panel tab value
    const handlePanelTabs = (e, panelTabVal) => {
        if (panelTabVal === 2) {
            dispatch(getGlobalRPA()).then((res) => {
                let data = res.payload.data.data;
                setRPAList(data)
            })
        }
        setPanelTabVal(panelTabVal);
    }

    // function to toggle edit
    const toggleEdit = (e, toggleName) => {
        const { name } = e.target;
        if (toggleName === name) {
            setDisableFields(toggleName)
        } else {
            setDisableFields("")
        }
    }

    // function to get users
    useEffect(() => {
        dispatch(getUsers(users)).then((res) => {
            let users = res.payload.data.data;
            let activeUsers = users.filter((item) => (item.status === true && item.active === true) || (item.active === false && item.activeExpires > Date.now()));
            setUsers(activeUsers || []);
            setUsersCopy(users || []);
            setFilterVal("Active");
        })
    }, [0])

    const getUsersList = () => {
        dispatch(getUsers(users)).then((res) => {
            let data = res.payload.data.data;
            console.log(data);
            setUsers(data);
        })
    }

    // function to get uploaded codes data
    useEffect(() => {
        dispatch(getUploadedCodes(codesData)).then((res) => {
            let codeData = res.payload.data.data;
            // console.log("++++++++++++++++++", codeData);
            codeData && setCodesData(codeData)
        })
    }, []);

    const getCodes = () => {
        dispatch(getUploadedCodes(codesData)).then((res) => {
            let codeData = res.payload.data.data;
            // console.log("------------------", codeData);
            codeData && setCodesData(codeData)
        })
    }

    // function to fetch master settings
    useEffect(() => {
        dispatch(getMasterSettings(settingsInfo)).then((res) => {
            let settingsRes = res.payload.data.data[0];
            console.log("settingsRes", settingsRes)
            setSettingsInfo(settingsRes)
        })
    }, {})

    // function to handle setting value change
    const handleSettingChange = (e) => {
        const { name, value } = e.target;
        console.log(value)
        setSettingsInfo({ ...settingsInfo, [name]: value });
    }

    // function to edit master settings
    const handleEditSettings = () => {
        setDisableFields("");
        console.log("settingsInfo", settingsInfo)
        dispatch(editMasterSettings(settingsInfo)).then((res) => {
            console.log(res)
        })
    }

    // function to handle add user popup
    const handleAddUserPopup = () => {
        setAddUserPopup(!addUserPopup);
    }

    // function to delete user
    const handleDeleteUser = (email) => {
        console.log(email)
        dispatch(deleteUser({ email: email })).then((res) => {
            let user = res.payload;
            console.log(user);
            getUsersList();
        })
    }

    // function to resend invite to a user
    const handleResendInvite = (id) => {
        dispatch(resendInvite({ id })).then((res) => {
            let invite = res.payload;
            console.log(invite);
        })
    }

    // function to handle edit user pop up
    const handleEditUserPopup = (user) => {
        console.log(user)
        setEditUserPopup(!editUserPopup);
        setUserData(user);
    }

    return (
        <section>
            <div className='row tabDiv'>
                <div className='col-sm-12 col-lg-12'>
                    <Tabs
                        value={tabValue}
                        variant='fullWidth'
                        indicatorColor='primary'
                        onChange={handleTabs}>
                        <Tab label='Team' />
                        <Tab label='Admin Settings' />
                    </Tabs>
                </div>
            </div>
            {tabValue === 0 &&
                <>
                    <div className='mt-4'>
                        <div className='col-lg-12 col-sm-12'>
                            <div className='setting_filter'>
                                <div className='filter_set font-face-futura'>
                                    <span className='mx-3'>Filter By:</span>
                                    <select className='filter_drop text-center' name="filter" value={filterVal ? filterVal : 'All'} onChange={handleFilter}>
                                        {filterItems.map((fil) => (
                                            <option key={fil.key} value={fil.value}>{fil.value}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <input
                                        type='text'
                                        className='setting-search font-face-futura'
                                        value={searchVal}
                                        placeholder='Search by user name...'
                                        onChange={handleSearch} />
                                </div>
                                <Button variant='contained' className='mx-3 add_btn' onClick={handleAddUserPopup}>Invite</Button>
                                <AddUser openAddUser={addUserPopup} closeAddUser={handleAddUserPopup} getUsers={getUsersList} />
                            </div>
                        </div>
                    </div>
                    <div className='mt-3 settingDiv'>
                        <div className='table-responsive setting-table font-face-futura'>
                            <table className='table tables'>
                                <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users && users.length > 0 ?
                                        (users
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            ).map((user, idx) => (
                                                <tr>
                                                    <td>{user.first_name}</td>
                                                    <td>{user.last_name}</td>
                                                    <td>{user.email}</td>
                                                    <td>{(user.status === true && user.active === true) ? "Active" :
                                                        (user.status === true && user.active === false && user.activeExpires > Date.now()) ? 'Invite Sent' :
                                                            (user.status === true && user.active === false && user.activeExpires < Date.now()) ? 'Invite Expired' : "Inactive"}</td>
                                                    {/* <td>{user.status === true ? "Active" : user.active === false ? user.activeExpires > Date.now() ? 'Invite Sent' : 'Invite Expired' : "Inactive"}</td> */}
                                                    <td>
                                                        {user.status === true ?
                                                            // <Tooltip title={roleDetail.roleId === 1 ? "" : "You don't have permission" } followCursor arrow>
                                                            <IconButton>
                                                                <input
                                                                    type="image"
                                                                    // disabled={roleDetail.roleId === 1 ? false : true }
                                                                    className="mx-1"
                                                                    alt=""
                                                                    src={editIcon}
                                                                    onClick={() => handleEditUserPopup(user)}
                                                                />
                                                            </IconButton>
                                                            // </Tooltip> 
                                                            :
                                                            user.active === false ? user.activeExpires > Date.now() ?
                                                                // <Tooltip title={roleDetail.roleId === 1 ? "" : "You don't have permission" } followCursor arrow>
                                                                <IconButton>
                                                                    <input
                                                                        type="image"
                                                                        // disabled={roleDetail.roleId === 1 ? false : true }
                                                                        className="mx-1"
                                                                        alt=""
                                                                        src={editIcon}
                                                                        onClick={() => handleEditUserPopup(user)}
                                                                    />
                                                                </IconButton>
                                                                // </Tooltip> 
                                                                : <>
                                                                    {/* <Tooltip title={roleDetail.roleId === 1 ? "" : "You don't have permission" } followCursor arrow> */}
                                                                    <input
                                                                        type="image"
                                                                        // disabled={roleDetail.roleId === 1 ? false : true }
                                                                        className="mx-1"
                                                                        alt=""
                                                                        src={resendInvite}
                                                                        onClick={handleResendPopup}
                                                                    />
                                                                    {/* </Tooltip> */}
                                                                    <RoAlert
                                                                        // showResendPopup={showPopupWithoutText}
                                                                        show={openResendPopup}
                                                                        handleClose={() => setOpenResendPopup(false)}
                                                                        handleSubmit={() => {
                                                                            handleResendInvite(user.id)
                                                                            setOpenResendPopup(false);
                                                                        }}
                                                                        title="Resend Invite?"
                                                                        type="info"
                                                                        content={
                                                                            <div className="alert-footer-text text-center mt-5 mb-4" style={{ letterSpacing: "-0.06em" }}>
                                                                                This user did not respond to the invite within 7 calendar days...<br />
                                                                                We can resend the invite for you!
                                                                            </div>
                                                                        }
                                                                    />
                                                                </>
                                                                :
                                                                // <Tooltip title={roleDetail.roleId === 1 ? "" : "You don't have permission" } followCursor arrow>
                                                                <IconButton>
                                                                    <input
                                                                        type="image"
                                                                        // disabled={roleDetail.roleId === 1 ? false : true }
                                                                        className="mx-1"
                                                                        alt=""
                                                                        src={editIcon}
                                                                        onClick={() => handleEditUserPopup(user)}
                                                                    />
                                                                </IconButton>
                                                            // </Tooltip>
                                                        }
                                                        {/* <img className='iconImg mx-2' src={editIcon} alt="edit" /> */}
                                                        <img className='iconImg mx-2' src={deleteIcon} alt="delete" onClick={() => handleDeleteUser(user.email)} />
                                                    </td>
                                                </tr>
                                            ))) : (
                                            <tr>
                                                <td colSpan={9} className="noRecord text-center">
                                                    No records found!
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                component="div"
                                count={users?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{ "aria-label": "Previous Page" }}
                                nextIconButtonProps={{ "aria-label": "Next Page" }}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleRowsPerPage}
                            />
                            <EditUser openEdit={editUserPopup} userData={userData} closeEdit={handleEditUserPopup} users={getUsersList} />
                        </div>
                    </div>
                </>
            }

            {tabValue === 1 &&
                <>
                    <div className='my-4 row' style={{ width: '100%' }}>
                        <div className='col-sm-12 col-lg-12'>
                            <Card className='settingCard'>
                                <CardHeader className='settingCardHead' title={<h5 className="my-2 text-white font-face-futura">Admin Panel Settings</h5>} />
                                <CardContent className='settingContent'>
                                    <div className='row panelTabDiv'>
                                        <div className='col-sm-12 col-lg-12'>
                                            <Tabs
                                                value={panelTabVal}
                                                variant='fullWidth'
                                                indicatorColor='primary'
                                                onChange={handlePanelTabs}>
                                                <Tab label='Master Settings' />
                                                <Tab label='Data' />
                                                <Tab label='RPA' />
                                            </Tabs>
                                        </div>
                                    </div>
                                    {panelTabVal === 0 &&
                                        <>
                                            <div className='masterDiv'>
                                                <div className='row'>
                                                    <div className='col-lg-12 col-sm-12 position-relative'>
                                                        <label htmlFor="time"><strong>Time Frame for Checking Eligibility:</strong></label>
                                                        <select className={disableFields === 'timeFrame' ? 'modal_select' : 'disabled modal_select'}
                                                            disabled={disableFields === 'timeFrame' ? false : true}
                                                            value={settingsInfo.timeFrameForCheckingEligibility}
                                                            name='timeFrameForCheckingEligibility' onChange={handleSettingChange}>
                                                            <option value="Every DOS">Every DOS</option>
                                                            <option value="Weekly">Weekly</option>
                                                            <option value="Monthly">Monthly</option>
                                                        </select>
                                                        {disableFields === "timeFrame" ?
                                                            <DoneIcon className='done' onClick={handleEditSettings} /> :
                                                            <img src={editIcon} alt="edit" className='form-btn' name="timeFrame" onClick={(e) => toggleEdit(e, "timeFrame")} />
                                                        }
                                                        {/* <p className="text-danger" style={style}>{errors.practiceName}</p> */}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='my-2 col-lg-12 col-sm-12 position-relative'>
                                                        <label htmlFor="time"><strong>Default Provider:</strong></label>
                                                        <select className={disableFields === 'provider' ? 'modal_select' : 'disabled modal_select'}
                                                            disabled={disableFields === 'provider' ? false : true}
                                                            value={settingsInfo.defaultProvider}
                                                            name='defaultProvider' onChange={handleSettingChange}>
                                                            <option value="Scheduling">Scheduling</option>
                                                            <option value="Rendering">Rendering</option>
                                                        </select>
                                                        {disableFields === "provider" ?
                                                            <DoneIcon className='done' onClick={handleEditSettings} /> :
                                                            <img src={editIcon} alt="edit" className='form-btn' name="provider" onClick={(e) => toggleEdit(e, "provider")} />
                                                        }
                                                        {/* <p className="text-danger" style={style}>{errors.practiceName}</p> */}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='my-2 col-lg-12 col-sm-12 position-relative'>
                                                        <label htmlFor="defaultPrice"><strong>Default Price Per Transaction:</strong></label>
                                                        <input className={disableFields === "defaultPrice" ? 'inputField' : 'inputField disabled'}
                                                            disabled={disableFields === "defaultPrice" ? false : true}
                                                            value={settingsInfo.defaultPricePerTransaction}
                                                            name="defaultPricePerTransaction" onChange={handleSettingChange} />
                                                        {disableFields === "defaultPrice" ?
                                                            <DoneIcon className='done' onClick={handleEditSettings} /> :
                                                            <img src={editIcon} alt="edit" className='form-btn' name="defaultPrice" onClick={(e) => toggleEdit(e, "defaultPrice")} />
                                                        }
                                                        {/* <p className="text-danger" style={style}>{errors.practiceAcronym}</p> */}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='my-2 col-lg-12 col-sm-12 position-relative'>
                                                        <label htmlFor="defaultPrice"><strong>Cost Per Eligibility:</strong></label>
                                                        <input className={disableFields === "cost" ? 'inputField' : 'inputField disabled'}
                                                            disabled={disableFields === "cost" ? false : true}
                                                            value={settingsInfo.costPerEligbility}
                                                            name="costPerEligbility" onChange={handleSettingChange} />
                                                        {disableFields === "cost" ?
                                                            <DoneIcon className='done' onClick={handleEditSettings} /> :
                                                            <img src={editIcon} alt="edit" className='form-btn' name="cost" onClick={(e) => toggleEdit(e, "cost")} />
                                                        }
                                                        {/* <p className="text-danger" style={style}>{errors.practiceAcronym}</p> */}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='my-2 col-lg-12 col-sm-12 position-relative'>
                                                        <label htmlFor="defaultCharge"><strong>Default Charge Per Active Provider?</strong></label>
                                                        <select className={disableFields === "defaultCharge" ? 'modal_select' : 'disabled modal_select'}
                                                            disabled={disableFields === "defaultCharge" ? false : true}
                                                            value={settingsInfo.defaultChargePerProvider}
                                                            name='defaultChargePerProvider' onChange={handleSettingChange}>
                                                            <option value="true">Yes</option>
                                                            <option value="false">No</option>
                                                        </select>
                                                        {disableFields === "defaultCharge" ?
                                                            <DoneIcon className='done' onClick={handleEditSettings} /> :
                                                            <img src={editIcon} alt="edit" className='form-btn' name="defaultCharge" onClick={(e) => toggleEdit(e, "defaultCharge")} />
                                                        }
                                                        <small className='smallTxt'>Clients will not be able to run claims for providers that are not active in their practice settings</small>
                                                        {/* <p className="text-danger" style={style}>{errors.practiceEmail}</p> */}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='my-2 col-lg-12 col-sm-12 position-relative'>
                                                        <label htmlFor="minimumTransaction"><strong>Minimum Transaction Per Provider:</strong></label>
                                                        <input className={disableFields === "minTrans" ? 'inputField' : 'inputField disabled'}
                                                            disabled={disableFields === "minTrans" ? false : true}
                                                            value={settingsInfo.minimumTransactionPerProvider}
                                                            name="minimumTransactionPerProvider" onChange={handleSettingChange} />
                                                        {disableFields === "minTrans" ?
                                                            <DoneIcon className='done' onClick={handleEditSettings} /> :
                                                            <img src={editIcon} alt="edit" className='form-btn' name="minTrans" onClick={(e) => toggleEdit(e, "minTrans")} />
                                                        }
                                                        {/* <p className="text-danger" style={style}>{errors.practicePassword}</p> */}
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='my-2 col-lg-12 col-sm-12 position-relative'>
                                                        <label htmlFor="rpaPrice"><strong>Default Price for RPA:</strong></label>
                                                        <input className={disableFields === "rpaPrice" ? 'inputField' : 'inputField disabled'}
                                                            disabled={disableFields === "rpaPrice" ? false : true}
                                                            value={settingsInfo.defaultPriceForRPA ? settingsInfo.defaultPriceForRPA : '$250'}
                                                            name="defaultPriceForRPA" onChange={handleSettingChange} />
                                                        {disableFields === "rpaPrice" ?
                                                            <DoneIcon className='done' onClick={handleEditSettings} /> :
                                                            <img src={editIcon} alt="edit" className='form-btn' name="rpaPrice" onClick={(e) => toggleEdit(e, "rpaPrice")} />
                                                        }
                                                        {/* <p className="text-danger" style={style}>{errors.practicePassword}</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {panelTabVal === 1 &&
                                        // <>
                                        //     <div className='dataDiv'>
                                        //         {codeData.map((code, idx) => (
                                        //         <Card className='my-2 settingCard' key={idx}>
                                        //             <CardHeader className='settingCardHead' title={<h5 className="my-2 text-white">{code.title}</h5>} />
                                        //             <CardContent className='settingContent padding0'>
                                        //                 <div className='divCard'>
                                        //                     <p>
                                        //                         <strong>Last Updated: </strong>
                                        //                         <span>{codesData?.lastUpdated}</span>
                                        //                     </p>
                                        //                     <p>
                                        //                         <strong>{code.title} Count: </strong>
                                        //                         <span>{codesData?.count}</span>
                                        //                     </p>
                                        //                     <p>
                                        //                         <strong>Updated By: </strong>
                                        //                         <span>Loren Dilger</span>
                                        //                     </p>
                                        //                 </div>
                                        //             </CardContent>
                                        //             <CardActions>
                                        //                 <label className='uploadNew'>
                                        //                     <input type="file" style={{ display: "none" }} accept='.csv' onChange={(e) => uploadCode(e, code.title)} />
                                        //                     <span>UPLOAD NEW</span>
                                        //                 </label>
                                        //             </CardActions>
                                        //         </Card>
                                        //         ))}
                                        //     </div>
                                        // </>


                                        <div style={{ marginTop: '40px' }}>
                                            {codesData?.map((item, idx) => {
                                                switch (item.type) {
                                                    case 'Procedure Codes':
                                                        return <>
                                                            <UploadCard
                                                                type="Procedure Codes"
                                                                lastUpdated={item.lastUpdated}
                                                                count={item.procedureCodeCount}
                                                                updatedBy={item.updatedBy} getCodes={getCodes} /><br />
                                                        </>
                                                        break;
                                                    case 'Diagnosis Codes':
                                                        return <>
                                                            <UploadCard
                                                                type="Diagnosis Codes"
                                                                lastUpdated={item.lastUpdated}
                                                                count={item.diagnosisCodeCount}
                                                                updatedBy={item.updatedBy} getCodes={getCodes} /><br />
                                                        </>
                                                        break;
                                                    case 'Modifier Codes':
                                                        return <>
                                                            <UploadCard
                                                                type="Modifier Codes"
                                                                lastUpdated={item.lastUpdated}
                                                                count={item.modifierCodeCount}
                                                                updatedBy={item.updatedBy} getCodes={getCodes} /><br />
                                                        </>
                                                        break;
                                                    case 'Place of Service Codes':
                                                        return <>
                                                            <UploadCard
                                                                type="Place of Service Codes"
                                                                lastUpdated={item.lastUpdated}
                                                                count={item.placeOfServiceCodeCount}
                                                                updatedBy={item.updatedBy} getCodes={getCodes} /><br />
                                                        </>
                                                        break;
                                                    case 'Trizetto Payers':
                                                        return <>
                                                            <UploadCard
                                                                type="Payer Name"
                                                                lastUpdated={item.lastUpdated}
                                                                count={item.trizettoPayersCount}
                                                                updatedBy={item.updatedBy} getCodes={getCodes} /><br />
                                                        </>
                                                        break;
                                                    case 'Advantage Plans':
                                                        return <UploadCard
                                                            type="Legal Entity Name"
                                                            lastUpdated={item.lastUpdated}
                                                            count={item.advantagePlansCount}
                                                            updatedBy={item.updatedBy} getCodes={getCodes} />
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            })}
                                        </div>
                                    }

                                    {panelTabVal === 2 &&
                                        <div className='mt-4'>
                                            <div className='table-responsive font-face-futura'>
                                                <table className='table client-table'>
                                                    <thead className='tableHead text-white'>
                                                        <tr>
                                                            <th>RPA ID</th>
                                                            <th>RPA Name</th>
                                                            <th>API Link</th>
                                                            <th>Created Date</th>
                                                            <th>Modified Date</th>
                                                            <th>Status</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {RPAList && RPAList?.length > 0 ?
                                                            (RPAList
                                                                .slice(
                                                                    page * rowsPerPage,
                                                                    page * rowsPerPage + rowsPerPage
                                                                ).map((rpa, idx) => (
                                                                    <tr>
                                                                        <td>
                                                                            <input className={toggleId === rpa.id ? 'rpa_input text-center' : 'noInput'}
                                                                            type='text' 
                                                                            name='rpa_id' style={{width: '80px'}}
                                                                            value={rpa.rpa_id} onChange={(e) => handleRPAInputs(e, idx)} />
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            <input className={toggleId === rpa.id ? 'rpa_input text-center' : 'noInput'}
                                                                                type='text'
                                                                                name="name"
                                                                                value={rpa.name} onChange={(e) => handleRPAInputs(e, idx)} />
                                                                        </td>
                                                                        <td className='text-center one-line-ellipsis' title={rpa.link}>
                                                                            <input className={toggleId === rpa.id ? 'rpa_input text-center' : 'noInput'}
                                                                                type={toggleId === rpa.id ? tempPass : 'password'}
                                                                                name="link"
                                                                                value={rpa.link} onChange={(e) => handleRPAInputs(e, idx)} />
                                                                            {toggleId === rpa.id && 
                                                                            <img src={tempPass === 'password' ? hidePassword : showPassword} 
                                                                            alt="password" className='mx-2 form-img' onClick={() => showTempPass(idx)} />}
                                                                        </td>
                                                                        <td className='text-center'>{1 + new Date(rpa.createdAt).getMonth() + '/' + new Date(rpa.createdAt).getDate() + '/' + new Date(rpa.createdAt).getFullYear()}</td>
                                                                        <td className='text-center'>{1 + new Date(rpa.createdAt).getMonth() + '/' + new Date(rpa.updatedAt).getDate() + '/' + new Date(rpa.createdAt).getFullYear()}</td>
                                                                        <td className='text-center'>
                                                                            {toggleId === rpa.id ?
                                                                                <select className='rpa_select text-center' name='status'
                                                                                    value={rpa.status}
                                                                                    onChange={(e) => handleRPAInputs(e, idx)}>
                                                                                    <option value="Active">Active</option>
                                                                                    <option value="Deactivated">Deactivated</option>
                                                                                    <option value="Paused">Paused</option>
                                                                                </select> :
                                                                                <span>{rpa.status}</span>
                                                                            }
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            {toggleId == rpa.id ?
                                                                                <>
                                                                                    <IconButton><CloseIcon className='rpa_close' onClick={() => toggleRPAEdit(rpa.id)} /></IconButton>
                                                                                    <IconButton><DoneIcon className='rpa_done' onClick={() => editRPAData(rpa.id)} /></IconButton>
                                                                                </> :
                                                                                <img className='iconImg mx-2' src={editIcon} alt="edit" onClick={() => toggleRPAEdit(rpa.id)} />
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))) : (
                                                                <tr>
                                                                    <td colSpan={9} className="noRecord text-center">
                                                                        No records found!
                                                                    </td>
                                                                </tr>
                                                            )}
                                                    </tbody>
                                                </table>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 20]}
                                                    component="div"
                                                    count={RPAList?.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                                                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                                                    onPageChange={handlePageChange}
                                                    onRowsPerPageChange={handleRowsPerPage}
                                                />
                                            </div>
                                        </div>
                                    }
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </>
            }
        </section>
    )
}