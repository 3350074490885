import { createSlice } from "@reduxjs/toolkit";
import { editMasterSettings, getMasterSettings, uploadCodes, getUploadedCodes, getUsers, addUser, editUser, deleteUser, resendInvite, acceptInvite, getGlobalRPA, downloadCodes, updateRPA } from "./settingsActions";

const initialState = {
    settingsInfo: null,
    isLoading: false,
    isSuccess: false,
    errorMsg: '',
    codeType: '',
    codes: [],
    users: [],
    userInfo: null,
    inviteInfo: null,
    globalRPA: null
}

export const settingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {},
    extraReducers: {
        // get users action
        [getUsers.pending]: (state) => {
            state.isLoading = true;
        },
        [getUsers.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.users = payload;
        },
        [getUsers.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMsg = payload;
        },

        // add user actions
        [addUser.pending]: (state) => {
            state.isLoading = true;
        },
        [addUser.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.userInfo = payload;
        },
        [addUser.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMsg = payload;
        },

        // edit user actions
        [editUser.pending]: (state) => {
            state.isLoading = true;
        },
        [editUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            const {arg: {id}} = action.meta;
            if(id){
                state.users = state.users.map((item) => item.id === id ? action.payload : item)
            }
        },
        [editUser.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMsg = payload;
        },

        // delete user actions
        [deleteUser.pending]: (state) => {
            state.isLoading = true;
        },
        [deleteUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            // const {arg: {id}} = action.meta;
            // if(id){
            //     state.users = state.users.filter(item => item.id !== id)
            // }
        },
        [deleteUser.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMsg = payload;
        },

        // accept invite and set password actions
        [acceptInvite.pending]: (state) => {
            state.isLoading = true;
        },
        [acceptInvite.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.inviteInfo = payload;
        },
        [acceptInvite.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMsg = payload;
        },

        // resend invite actions
        [resendInvite.pending]: (state) => {
            state.isLoading = true;
        },
        [resendInvite.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.userInfo = payload
        },
        [resendInvite.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMsg = payload;
        },

        // get master settings action
        [getMasterSettings.pending]: (state) => {
            state.isLoading = true;
        },
        [getMasterSettings.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.settingsInfo = payload;
        },
        [getMasterSettings.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMsg = payload;
        },

        // edit master settings action
        [editMasterSettings.pending]: (state) => {
            state.isLoading = true;
        },
        [editMasterSettings.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.settingsInfo = payload;
        },
        [editMasterSettings.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.errorMsg = payload;
        },

        // upload codes actions
        [uploadCodes.pending]: (state) => {
            state.isLoading = true;
        },
        [uploadCodes.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.codeType = payload;
            state.codes = payload;
        },
        [uploadCodes.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.errorMsg = payload;
        },

        // get codes action
        [getUploadedCodes.pending]: (state) => {
            state.isLoading = true;
        },
        [getUploadedCodes.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.codes = payload;
        },
        [getUploadedCodes.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.errorMsg = payload;
        },

        [downloadCodes.pending]: (state) => {
            state.isLoading = true;
        },
        [downloadCodes.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.codes = payload;
        },
        [downloadCodes.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.errorMsg = payload;
        },
        // Get RPA Code 
        [getGlobalRPA.pending]: (state) => {
            state.isLoading = true;
        },
        [getGlobalRPA.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.globalRPA = payload;
        },
        [getGlobalRPA.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.errorMsg = payload;
        },
        // update RPA
        [updateRPA.pending]: (state) => {
            state.isLoading = true;
        },
        [updateRPA.fulfilled]: (state, {payload}) => {
            state.isLoading = false;
            state.globalRPA = payload;
        },
        [updateRPA.rejected]: (state, {payload}) => {
            state.isLoading = false;
            state.globalRPA = payload;
            state.errorMsg = payload;
        }
    }
})

export default settingSlice.reducer;