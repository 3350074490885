import React from 'react';
import {Routes, Route, Navigate, useNavigate, useLocation} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Login } from './features/Auth/Login/Login';
import { Clients } from './features/AdminConsole/Clients/Clients';
import { Invoice } from './features/AdminConsole/Invoice/Invoice';
import { Tickets } from './features/AdminConsole/Tickets/Tickets';
import { history } from '../src/features/Auth/Login/authAction';
import { Settings } from '../src/features/Settings/Settings';
import { Logs } from './features/AdminConsole/Logs/logs';
import { LogDetails } from './features/AdminConsole/Logs/LogDetails';
import EditClient from './features/AdminConsole/Clients/EditClient';
import InviteUser from './features/Settings/InviteUser';
import ForgotPassword from './features/Auth/Login/ForgotPassword';

function PrivateRoute({ children }) {
  // const {userToken} = useSelector((state) => state.auth);
  const userToken = sessionStorage.getItem('userToken') ? sessionStorage.getItem('userToken') : null
  
  if (!userToken) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/" state={{from: history.location}} />
  }
  
  // authorized so return child components
  return children;
}

function AppRoutes() {
  // const {userToken} = useSelector((state) => state.auth);
  const userToken = sessionStorage.getItem('userToken') ? sessionStorage.getItem('userToken') : null
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <Routes>
      <Route path='/' element={!userToken ? <Login /> : <Navigate to="/admin-panel/clients" />} />
      <Route path='/admin-panel/clients' element={<PrivateRoute><Clients /></PrivateRoute>} />
      <Route path='/admin-panel/editClient/:id' element={<PrivateRoute><EditClient /></PrivateRoute>} />
      <Route path='/admin-panel/invoice' element={<PrivateRoute><Invoice /></PrivateRoute>} />
      <Route path='/admin-panel/tickets' element={<PrivateRoute><Tickets /></PrivateRoute>} />
      <Route path='/settings' element={<PrivateRoute><Settings /></PrivateRoute>} />
      <Route path='/logs' element={<PrivateRoute><Logs /></PrivateRoute>} />
      <Route path='/register' element={<InviteUser />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='log-details' element={<PrivateRoute><LogDetails /></PrivateRoute>} />
    </Routes>
  );
}

export default AppRoutes;
