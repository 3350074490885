import axios from 'axios';

const API = axios.create({
    // baseURL: 'http://localhost:3000/',
    // baseURL: 'https://ruleout-hltg.chetu.com/',
    baseURL: "https://backenddev.ruleout.com/",
    // baseURL: "https://dev1backend.ruleout.com/",
    // baseURL: "https://backendapp.ruleout.com/",
    headers: {
        'Authorization': sessionStorage.getItem('userToken') ? `Bearer ${JSON.parse(sessionStorage.getItem('userToken'))}` : null,
        'Content-Type': 'application/json;charset=UTF-8'
    },
    // get headers() {
    //     return this.headers;
    // },
    // set headers(value) {
    //     this.headers = value;
    // },
    responseType: 'json'
});

// const getAuthToken = () => {
//     const token = sessionStorage.getItem("userToken");
//     console.log(token)
//     if (token) {
//         const parsed = 'Bearer ' + token;
//         if (!!parsed) {
//             return parsed;
//         }
//     }
// }

// API.interceptors.request.use(
//     async config => {
//         if (sessionStorage.getItem('userToken')) {
//             config.headers['Authorization'] = getAuthToken();
//         }
//         return config;
//     },
//     error => {
//         return Promise.reject(error);
//     }
// );

export default API;