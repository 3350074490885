import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../Api";

export const getGlobalRPA = createAsyncThunk('RPA/getGlobalRpa', async() => {
    try{
        const rpa = await API.get('getRPA');
        return rpa;
    } catch(err){
        console.error(err);
    }
})

export const createGlobalRPA = createAsyncThunk('RPA/create', async({rpa_id, name, link, status}) => {
    let body_data = {
        rpa_id: rpa_id,
        name: name,
        link: link,
        status: status
    }
    try{
        const rpa = await API.post('createRPA_master', body_data);
        console.log(rpa);
        return rpa;
    }catch(err){
        console.error(err);
    }
})

export const updateRPA = createAsyncThunk('RPA/update', async({id, clientId, rpa_id, name, link, status}) => {
    let body_data = {
        clientId: clientId,
        rpa_id: rpa_id,
        name: name,
        link: link,
        status: status
    }
    try{
        const rpa = await API.post(`updateRPA/${id}`, body_data);
        console.log(rpa);
        return rpa;
    }catch(err){
        console.error(err);
    }
})

export const getUsers = createAsyncThunk('users/get', async() => {
    try{
        const users = await API.get('getAllAdminUsers');
        return users;
    }catch(err){
        console.error(err);
    }
})

export const addUser = createAsyncThunk('users/add', async({
    first_name, last_name, email, roleid
}) => {
    let body_data = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        roleid: roleid
    }
    try {
        const user = await API.post('addAdminUser', body_data);
        return user;
    }catch(err){
        console.error(err);
    }
})

export const editUser = createAsyncThunk('users/edit', async({first_name, last_name, email, roleid, newPassword, status}) => {
    let body_data = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        roleid: roleid,
        newPassword: newPassword,
        status: status
    }
    console.log(body_data)
    try{
        const user = await API.put('updateAdminUser', body_data);
        console.log(user)
        return user;
    }catch(err){
        console.error(err);
    }
})

export const deleteUser = createAsyncThunk('users/delete', async({email}) => {
    console.log(email)
    try{
        const user = await API.put('deleteAdminUser', {email: email});
        console.log(user)
        return user;
    }catch(err){
        console.error(err);
    }
})

export const acceptInvite = createAsyncThunk('users/acceptInvite', async({email, password, confirmPassword}) => {
    let body_data = {
        email: email,
        password: password,
        confirmPassword: confirmPassword
    }
    console.log(body_data)
    try{
        const invite = await API.post('setAdminUserPassword', body_data);
        console.log(invite)
        return invite;
    }catch(err){
        console.error(err);
    }
})

export const resendInvite = createAsyncThunk('users/resendInvite', async({id}) => {
    try{
        const invite = await API.post('resendAdminUserInvite', {id: id})
        console.log(invite)
        return invite;
    }catch(err){
        console.error(err);
    }
})

export const getMasterSettings = createAsyncThunk('settings/get', async() => {
    try {
        const data = await API.post('getMasterSettingData', {})
        // console.log("data", data);
        return data;
    }catch (err) {
        console.error(err);
    }
})

export const editMasterSettings = createAsyncThunk('settings/edit', async({
    timeFrameForCheckingEligibility, 
    defaultProvider, 
    defaultPricePerTransaction, 
    costPerEligbility, 
    defaultChargePerProvider, 
    minimumTransactionPerProvider,
    defaultPriceForRPA
}) => {
    const body_data = {
        timeFrameForCheckingEligibility: timeFrameForCheckingEligibility,
        defaultProvider: defaultProvider,
        defaultPricePerTransaction: defaultPricePerTransaction,
        costPerEligbility: costPerEligbility,
        defaultChargePerProvider: defaultChargePerProvider,
        minimumTransactionPerProvider: minimumTransactionPerProvider,
        defaultPriceForRPA: defaultPriceForRPA
    }
    console.log(body_data)
    try {
        const settingsData = await API.put('editMasterSetting', body_data);
        // console.log("settingsData", settingsData);
        return settingsData;
    }catch(err){
        console.error(err);
    }
})

export const uploadCodes = createAsyncThunk('settings/upload', async({type, codes}) => {
    let body_data = {
        type: type,
        codes: codes
    }
    try {
        const codes = await API.post('uploadCSV', body_data);
        // console.log(codes);
        return codes;
    }catch(err){
        console.error(err);
    }
})

export const getUploadedCodes = createAsyncThunk('settings/getCodes', async() => {
    try {
        const codes = await API.get('getMasterTables');
        // console.log(codes);
        return codes;
    }catch(err){
        console.error(err);
    }
})

export const downloadCodes = createAsyncThunk('settings/download', async({type}) => {
    try {
        const codes = await API.post('downloadMasterCodes', {type: type});
        console.log(codes);
        return codes;
    }catch(err){
        console.error(err);
    }
})