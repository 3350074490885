import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { addUser } from './settingsActions';

const AddUser = (props) => {
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState({
        first_name: '',
        last_name: '',
        email: '',
        roleid: ''
    });
    const [errors, setErrors] = useState({});
    const [userCanDo, setUserCanDo] = useState(['Everything']);
    const [userCantDo, setUserCantDo] = useState(['Nothing']);

    // function to handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        errors[name] = '';
        setUserInfo({ ...userInfo, [name]: value });
        setErrors(errors)
    }

    // function to add user
    const createUser = () => {
        const data = userInfo;
        console.log(data)

        dispatch(addUser(data)).then((res) => {
            const user = res.payload;
            console.log(user)
            setUserInfo({
                first_name: '',
                last_name: '',
                email: '',
                roleid: ''
            })
            props.closeAddUser();
            props.getUsers();
        })
    }

    return (
        <>
            {props.openAddUser &&
                <Dialog fullWidth
                    open={props.openAddUser}
                    onClose={props.closeAddUser}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className='modalTitle'>
                        <DialogTitle id="alert-dialog-title">
                            <span className='font-face-futura'>{"Add User"}</span>
                        </DialogTitle>
                    </div>
                    <DialogContent className='pos-relative'>
                        <div className='mt-2'>
                            <Card className='modalCard mb-4' style={{ height: '190px' }}>
                                <CardHeader className='modalCardHead' title={<h5 className="my-2 text-white font-face-futura">Profile</h5>} />
                                <CardContent className='modalContent padding-form'>
                                    <div className="row">
                                        <div className='col-lg-6 col-sm-6'>
                                            <label htmlFor="first_name"><strong className='strongTxt'>First Name:</strong></label>
                                            <input className='inputField' value={userInfo.first_name}
                                                name="first_name" onChange={handleInputChange} />
                                            {/* <p className="text-danger" style={style}>{errors.firsttName}</p> */}
                                        </div>
                                        <div className='col-lg-6 col-sm-6'>
                                            <label htmlFor="last_name"><strong className='strongTxt'>Last Name:</strong></label>
                                            <input className='inputField' value={userInfo.last_name}
                                                name="last_name" onChange={handleInputChange} />
                                            {/* <p className="text-danger" style={style}>{errors.lastName}</p> */}
                                        </div>
                                        <div className='my-1 col-lg-12 col-sm-12'>
                                            <label htmlFor="contactEmail"><strong className='strongTxt'>Email:</strong></label>
                                            <input className='inputField' value={userInfo.email}
                                                name="email" onChange={handleInputChange} />
                                            {/* <p className="text-danger" style={style}>{errors.practiceEmail}</p> */}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card className='modalCard mb-4' style={{ height: '300px' }}>
                                <CardHeader className='modalCardHead' title={<h5 className="my-2 text-white font-face-futura">Permissions</h5>} />
                                <CardContent className='modalContent'>
                                    {/* <div align="center" className='perHeadTxt'><span>Choose the users site permissions</span></div>
                                <div align="center" className='perSubHeadTxt'><span>Let's get them set up!</span></div>
                                <div align="center" className='mt-2 row'>
                                    <div className='col-lg-12 col-sm-12 position-relative'>
                                        <span>
                                            <label htmlFor="role"><strong className='strongTxt'>Permission Level:</strong></label>
                                        </span>
                                        <select className='modal_select' value={userInfo.roleid}
                                            name='role' onChange={handleInputChange}>
                                            <option value="1">System Administrator</option>
                                        </select>
                                    </div>
                                </div> */}
                                    <div align="center">
                                        <span className="contentUser">
                                            Choose the users site permissions
                                        </span>
                                    </div>
                                    <div align="center">
                                        <span className="mt-1 contentText ">
                                            Let’s get them set up!
                                        </span>
                                    </div>
                                    <div className="mt-3 flex-group">
                                        <div className="boxSizingDrop ms-0">
                                        <label htmlFor="roleid"><strong className='strongTxt2 ms-0'>Permission Level:</strong></label>
                                            <select
                                                fullWidth="true" name='roleid'
                                                className="modal_select ms-0"
                                                value={userInfo?.roleid}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Select</option>
                                                <option value="1">System Administrator</option>
                                                {/* {permissionValues &&
                                                    permissionValues.map((options, idx) => (
                                                    <option key={idx} value={options.id}>
                                                        {options.role_Name}
                                                    </option>
                                                    ))} */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mt-2 " >
                                        <div className="row font-face-futura">
                                            <div className="col-lg-6 col-sm-6 ">
                                                <div className="headingsText">
                                                    <strong>What can they do?</strong>
                                                </div>
                                                <ul className="mt-2 headingsText p-0">
                                                    {userCanDo &&
                                                        userCanDo.map((canDo, idx) => (
                                                            <li key={idx}>{canDo}</li>
                                                        ))}
                                                </ul>
                                            </div>

                                            <div className="col-lg-6 col-sm-6">
                                                <div className="headingsText">
                                                    <strong>What can't they do?</strong>
                                                </div>
                                                <div className="mt-2 headingsText">
                                                    {userCantDo &&
                                                        userCantDo.map((cantDo, idx) => (
                                                            <li key={idx}>{cantDo}</li>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            <footer className='set-btn'>
                                <div className='actionButton'>
                                    <Button fullWidth
                                        className="mt-2 mx-2 nevermindBtn"
                                        variant="outlined" onClick={props.closeAddUser}>
                                        <span className="btnSpan">Nevermind</span>
                                    </Button>
                                    <Button fullWidth
                                        className="mt-2 mx-2 addClientBtn"
                                        variant="outlined"
                                        onClick={createUser}>
                                        <span className="btnSpan">Send Invite!</span>
                                    </Button>
                                </div>
                            </footer>
                        </div>
                    </DialogContent>
                </Dialog>
            }
        </>
    )
}

export default AddUser;