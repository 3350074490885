import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle } from '@mui/material';
import editIcon from '../../../assets/icons/settingEdit.svg';
import deleteIcon from '../../../assets/icons/settingDelete.svg';
import cancelIcon from '../../../assets/icons/cancel.svg';
import { addClient, verifyPractice } from '../adminAction';
import { editMasterSettings } from '../../Settings/settingsActions';
import hidePassword from '../../../assets/icons/hidePassword.svg';
import showPassword from '../../../assets/icons/showPassword.svg';
import DoneIcon from '@mui/icons-material/Done';

const AddClient = (props) => {
    const dispatch = useDispatch();
    const [tempPass, setTempPass] = useState('password');
    const [conPass, setConPass] = useState('password');
    const [pracPass, setPracPass] = useState('password');
    const [clientInfo, setClientInfo] = useState({
        clientName: '',
        clientKey: '',
        provider: '',
        contactFirstName: '',
        contactLastName: '',
        contactPhone: '',
        contactEmail: '',
        tempPassword: '',
        confirmPassword: '',
        timeFrame: '',
        defaultPrice: '',
        defaultCharge: '',
        minimumTransaction: '',
        RPAEnabled: ''
    })
    const [practices, setPractices] = useState([]);
    const [practiceInfo, setPracticeInfo] = useState({
        practiceName: '',
        email: '',
        key: '',
        password: '',
        practiceEIN: '',
        practiceNPI: ''
    })
    const [patientId, setPatientId] = useState('');
    const [patientInfo, setPatientInfo] = useState({});
    const [showResult, setShowResult] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [disableFields, setDisableFields] = useState("");
    const [errors, setErrors] = useState({});

    // function to toggle password visibility
    const showTempPass = (e) => {
        setTempPass(tempPass === 'password' ? 'input' : 'password' );
    }
    const showConPass = (e) => {
        setConPass(conPass === 'password' ? 'input' : 'password' );
    }
    const showPracPass = (e) => {
        setPracPass(pracPass === 'password' ? 'input' : 'password' );
    }

    // function to handle input value change
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        errors[name] = '';
        setClientInfo({...clientInfo, [name]: value});
        setErrors(errors);
    }

    // function to handle form validation
    const isFormValid = () => {
        let error = {};
        let isValid = true;
        console.log(clientInfo)
        if(!clientInfo.clientName){
            isValid = false;
            error.clientName = "*Please enter client name";
        }
        if(!clientInfo.clientKey){
            isValid = false;
            error.clientKey = "*Please enter client key";
        }
        if(clientInfo.clientKey.length < 5){
            isValid = false;
            error.clientKey = "*Client key must be atleast 5 characters long";
        }
        if(!clientInfo.provider){
            isValid = false;
            error.provider = "*Please select provider";
        }
        if(!clientInfo.contactFirstName){
            isValid = false;
            error.contactFirstName = "*Please enter contact first name";
        }
        if(!clientInfo.contactLastName){
            isValid = false;
            error.contactLastName = "*Please enter contact last name";
        }
        if(!clientInfo.contactPhone){
            isValid = false;
            error.contactPhone = "*Please enter contact phone";
        }
        if(!clientInfo.contactEmail){
            isValid = false;
            error.contactEmail = "*Please enter contact email";
        }
        if(!clientInfo.tempPassword){
            isValid = false;
            error.tempPassword = "*Please enter the password";
        }
        if(!clientInfo.confirmPassword){
            isValid = false;
            error.confirmPassword = "*Please re-enter the password";
        }

        setErrors(error);
        return isValid;
    }

    // function to toggle edit
    const toggleEdit = (e, toggleName) => {
        const {name} = e.target;
        if(toggleName === name){
            setDisableFields(toggleName);
        }else {
            setDisableFields("")
        }
    }

    // function to edit invoice settings
    const editInvoiceSettings = () => {
        setDisableFields("")
        let data = {
            timeFrameForCheckingEligibility: clientInfo.timeFrame,
            defaultPricePerTransaction: clientInfo.defaultPrice,
            defaultChargePerProvider: clientInfo.defaultCharge,
            minimumTransactionPerProvider: clientInfo.minimumTransaction
        }
        dispatch(editMasterSettings(data)).then((res) => {
            console.log(res)
        })
    }

    // function to handle practice data value change
    const handlePracticeData = (e) => {
        const {name, value} = e.target;
        setPracticeInfo({...practiceInfo, [name]: value});
    }

    // function to verify patient
    const handleVerifyPractice = (patientId) => {
        console.log(patientId);
        const data = {
            patientId: patientId,
            practiceInfo: practiceInfo
        }
        dispatch(verifyPractice(data)).then((res) => {
            console.log(res);
            let patient = res.payload.data.patientInfo;
            if(res.payload.data.status === 'success'){
                setPatientInfo({
                    patientId: patient.ID,
                    patientName: patient.PatientFullName,
                    patientDOB: patient.DOB
                })
                // let {practiceEIN, ...rest} = practiceInfo
                // setPractices([...practices, rest]);
                setShowResult(true);
                setShowButton(true);
                setShowMessage(false);
            }else {
                setShowResult(false);
                setShowMessage(true);
                setShowButton(true);
            }
        })
    }

    // function to add practices
    const addPractice = () => {
        let {practiceEIN, ...rest} = practiceInfo
        setPractices([...practices, rest]);
        setShowResult(false);
        setShowButton(false);
        setShowMessage(false);
        setPatientId('')
        setPracticeInfo({
            practiceName: '',
            email: '',
            key: '',
            password: '',
            practiceEIN: '',
            practiceNPI: ''
        })
    }

    // function to handle patient info
    const handlePatientInfo = () => {
        setShowResult(false);
        setShowMessage(false);
        setShowButton(false);
        setPatientId('')
    }

    // function to add a new client
    const handleAddClient = () => {
        if(isFormValid()){
            console.log("practiceInfo", practiceInfo);
            const data = clientInfo;
            data["practices"] = practices;
            console.log("clientInfo", clientInfo);
            dispatch(addClient(data)).then((response) => {
                console.log(response);
                setClientInfo({
                    clientName: '',
                    clientKey: '',
                    provider: '',
                    contactFirstName: '',
                    contactLastName: '',
                    contactPhone: '',
                    contactEmail: '',
                    tempPassword: '',
                    confirmPassword: ''
                })
                setPracticeInfo({
                    practiceName: '',
                    practiceEmail: '',
                    practiceKey: '',
                    practiceEIN: '',
                    practiceNPI: ''
                })
                setPractices([]);
                props.closeClientModal();
                props.getClients();
                setShowResult(false);
                setShowMessage(false);
                setShowButton(false);
                setPatientId('');
            })
        }
    }

    // function to clear data on modal close
    const nevermind = () => {
        setClientInfo({
            clientName: '',
            clientKey: '',
            provider: '',
            contactFirstName: '',
            contactLastName: '',
            contactPhone: '',
            contactEmail: '',
            tempPassword: '',
            confirmPassword: '',
            // timeFrame: '',
            // defaultPrice: '',
            // defaultCharge: '',
            // minimumTransaction: '',
        })
        setPracticeInfo({
            practiceName: '',
            practiceEmail: '',
            practiceKey: '',
            practiceEIN: '',
            practiceNPI: ''
        })
        setPractices([]);
        props.closeClientModal();
        setShowResult(false);
        setShowMessage(false);
        setShowButton(false);
        setPatientId('');
    }

    // function to fetch default invoice settings
    useEffect(() => {
        const invoice = props.invoiceSettings;
        setClientInfo({ 
            clientName: '',
            clientKey: '',
            provider: '',
            contactFirstName: '',
            contactLastName: '',
            contactPhone: '',
            contactEmail: '',
            tempPassword: '',
            confirmPassword: '', 
            timeFrame: invoice.timeFrameForCheckingEligibility,
            defaultPrice: invoice.defaultPricePerTransaction,
            defaultCharge: invoice.defaultChargePerProvider,
            minimumTransaction: invoice.minimumTransactionPerProvider
        })
    }, [props.invoiceSettings])

    // function to remove practices
    const removePractice = (index) => {
        let updatedPractices = practices.filter((item, idx) => idx !== index);
        setPractices(updatedPractices);
        console.log(practices)
    }

    const style = {
        textAlign: 'left',
        height: 0,
        lineHeight: '16px'
    }
    
    return (
        <>
            {props.openClientModal && <Dialog fullWidth
                open={props.openClientModal}
                // onClose={props.closeClientModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <div className='modalTitle'>
                    <DialogTitle id="alert-dialog-title">
                        <span className='font-face-futura'>{"Add Client"}</span>
                    </DialogTitle>
                </div>
                <DialogContent className='pos-relative'>
                    <div className='mt-2'>
                        <Card className='modalCard mb-4' style={{height: '275px'}}>
                            <CardHeader className='modalCardHead' title={<h5 className="my-2 text-white font-face-futura">Profile</h5>} />
                            <CardContent className='modalContent'>
                                <div className="row mx-0">
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="clientName"><strong>Client Name:</strong></label>
                                        <input type='text' className='inputField'
                                            name="clientName" value={clientInfo.clientName} onChange={handleInputChange} />
                                        <p className="text-danger" style={style}>{errors.clientName}</p>
                                    </div>
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="clientKey"><strong>Client Key:</strong></label>
                                        <input type='text' className='inputField'
                                            name="clientKey" value={clientInfo.clientKey} onChange={handleInputChange} />
                                        <p className="text-danger" style={style}>{errors.clientKey}</p>
                                    </div>
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="provider"><strong>Scheduling or Rendering Provider:</strong></label>
                                        <select className='modal_select' 
                                        name='provider' value={clientInfo.provider} onChange={handleInputChange}>
                                            <option value="Scheduling">Scheduling</option>
                                            <option value="Rendering">Rendering</option>
                                        </select>
                                        <p className="text-danger" style={style}>{errors.provider}</p>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className='modalCard mb-4' style={{height: '420px'}}>
                            <CardHeader className='modalCardHead' title={<h5 className="my-2 text-white font-face-futura">Contact Info</h5>} />
                            <CardContent className='modalContent'>
                                <div className="row mx-0">
                                    <div className='col-lg-6 col-sm-6'>
                                        <label htmlFor="firstName"><strong>Contact First Name:</strong></label>
                                        <input type='text' className='inputField'
                                            name="contactFirstName" value={clientInfo.contactFirstName} onChange={handleInputChange} />
                                        <p className="text-danger" style={style}>{errors.contactFirstName}</p>
                                    </div>
                                    <div className='col-lg-6 col-sm-6'>
                                        <label htmlFor="lastName"><strong>Contact Last Name:</strong></label>
                                        <input type='text' className='inputField'
                                            name="contactLastName" value={clientInfo.contactLastName} onChange={handleInputChange} />
                                        <p className="text-danger" style={style}>{errors.contactLastName}</p>
                                    </div>
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="contactPhone"><strong>Contact Phone:</strong></label>
                                        <input type='text' className='inputField'
                                            name="contactPhone" value={clientInfo.contactPhone} onChange={handleInputChange} />
                                        <p className="text-danger" style={style}>{errors.contactPhone}</p>
                                    </div>
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="contactEmail"><strong>Contact Email:</strong></label>
                                        <input type='email' className='inputField'
                                            name="contactEmail" value={clientInfo.contactEmail} onChange={handleInputChange} />
                                        <p className="text-danger" style={style}>{errors.contactEmail}</p>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-sm-12 position-relative'>
                                            <label htmlFor="tempPassword"><strong>Temporary Password:</strong></label>
                                            <input className='inputField' type={tempPass}
                                                name="tempPassword" value={clientInfo.tempPassword} onChange={handleInputChange} />
                                            <img src={tempPass === 'password' ? hidePassword : showPassword} alt="password" className='form-btn' onClick={showTempPass} />
                                            <p className="text-danger" style={style}>{errors.tempPassword}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-sm-12 position-relative'>
                                            <label htmlFor="confirmPassword"><strong>Confirm Password:</strong></label>
                                            <input className='inputField' type={conPass}
                                                name="confirmPassword" value={clientInfo.confirmPassword} onChange={handleInputChange} />
                                            <img src={conPass === 'password' ? hidePassword : showPassword} alt="password" className='form-btn' onClick={showConPass} />
                                            <p className="text-danger" style={style}>{errors.confirmPassword}</p>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className='modalCard mb-4' style={{height: '430px'}}>
                            <CardHeader className='modalCardHead' title={<h5 className="my-2 text-white font-face-futura">Invoicing</h5>} />
                            <CardContent className='modalContent'>
                                <div className="row mx-0">
                                    <div className='row'>
                                        <div className='col-lg-12 col-sm-12 position-relative'>
                                            <label htmlFor="time"><strong>Time Frame for Checking Eligibility:</strong></label>
                                            <select className={disableFields === 'timeFrame' ? 'modal_select' : 'disabled modal_select'} 
                                            disabled={disableFields === 'timeFrame' ? false : true}
                                            name='timeFrame' value={clientInfo.timeFrame} onChange={handleInputChange}>
                                                <option value="Every DOS">Every DOS</option>
                                                <option value="Weekly">Weekly</option>
                                                <option value="Monthly">Monthly</option>
                                            </select>
                                            {disableFields === "timeFrame" ? 
                                            <DoneIcon className='done' onClick={editInvoiceSettings} /> :
                                            <img src={editIcon} alt="edit" className='form-btn' name="timeFrame" onClick={(e) => toggleEdit(e, "timeFrame")} /> 
                                            }
                                            {/* <p className="text-danger" style={style}>{errors.practiceName}</p> */}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='my-2 col-lg-12 col-sm-12 position-relative'>
                                            <label htmlFor="defaultPrice"><strong>Default Price Per Transaction:</strong></label>
                                            <input className={disableFields === "defaultPrice" ? 'inputField' : 'inputField disabled'} 
                                                disabled={disableFields === "defaultPrice" ? false : true}
                                                name="defaultPrice" value={clientInfo.defaultPrice} onChange={handleInputChange} />
                                            {disableFields === "defaultPrice" ? 
                                            <DoneIcon className='done' onClick={editInvoiceSettings} /> :
                                            <img src={editIcon} alt="edit" className='form-btn' name="defaultPrice" onClick={(e) => toggleEdit(e, "defaultPrice")} />
                                            }
                                            {/* <p className="text-danger" style={style}>{errors.practiceAcronym}</p> */}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='my-2 col-lg-12 col-sm-12 position-relative'>
                                            <label htmlFor="defaultCharge"><strong>Default Charge Per Active Provider?</strong></label>
                                            <select className={disableFields === "defaultCharge" ? 'modal_select' : 'disabled modal_select'} 
                                            disabled={disableFields === "defaultCharge" ? false : true} 
                                            name='defaultCharge' value={clientInfo.defaultCharge} onChange={handleInputChange}>
                                                <option value="true">Yes</option>
                                                <option value="false">No</option>
                                            </select>
                                            {disableFields === "defaultCharge" ? 
                                            <DoneIcon className='done' onClick={editInvoiceSettings} /> :
                                            <img src={editIcon} alt="edit" className='form-btn' name="defaultCharge" onClick={(e) => toggleEdit(e, "defaultCharge")} />
                                            }
                                            <small className='smallTxt'>Clients will not be able to run claims for providers that are not active in their practice settings</small>
                                            {/* <p className="text-danger" style={style}>{errors.practiceEmail}</p> */}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='my-2 col-lg-12 col-sm-12 position-relative'>
                                            <label htmlFor="minimumTransaction"><strong>Minimum Transaction Per Provider:</strong></label>
                                            <input className={disableFields === "minTrans" ? 'inputField' : 'inputField disabled'} 
                                                disabled={disableFields === "minTrans" ? false : true}
                                                name="minimumTransaction" value={clientInfo.minimumTransaction} onChange={handleInputChange} />
                                            {disableFields === "minTrans" ? 
                                            <DoneIcon className='done' onClick={editInvoiceSettings} /> :
                                            <img src={editIcon} alt="edit" className='form-btn' name="minTrans" onClick={(e) => toggleEdit(e, "minTrans")} />
                                            }
                                            {/* <p className="text-danger" style={style}>{errors.practicePassword}</p> */}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='my-2 col-lg-12 col-sm-12 position-relative'>
                                            <label htmlFor="rpaEnabled"><strong>RPA Enabled?</strong></label>
                                            <select className={disableFields === "rpaEnabled" ? 'modal_select' : 'disabled modal_select'} 
                                            disabled={disableFields === "rpaEnabled" ? false : true} 
                                            name='rpaEnabled' value={clientInfo.RPAEnabled} onChange={handleInputChange}>
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                            {disableFields === "rpaEnabled" ? 
                                            <DoneIcon className='done' onClick={editInvoiceSettings} /> :
                                            <img src={editIcon} alt="edit" className='form-btn' name="rpaEnabled" onClick={(e) => toggleEdit(e, "rpaEnabled")} />
                                            }
                                            <small className='smallTxt'>Clients will not be able to select RPA Flow if disabled</small>
                                            {/* <p className="text-danger" style={style}>{errors.practiceEmail}</p> */}
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className='modalCard prac mb-4'>
                            <CardHeader className='modalCardHead' title={<h5 className="my-2 text-white font-face-futura">Add Practices</h5>} />
                            <CardContent className='modalContent'>
                                <div className="row mx-0">
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="practiceName"><strong>Practice Name:</strong></label>
                                        <input className='inputField'
                                            name="practiceName"
                                            value={practiceInfo.practiceName}
                                            onChange={handlePracticeData} 
                                            />
                                        {/* <p className="text-danger" style={style}>{errors.practiceName}</p> */}
                                    </div>
                                    <div className='my-2 col-lg-6 col-sm-6'>
                                        <label htmlFor="practiceEmail"><strong>Practice Email:</strong></label>
                                        <input  className='inputField'
                                            name="email"
                                            value={practiceInfo.email}
                                            onChange={handlePracticeData} 
                                            />
                                        {/* <p className="text-danger" style={style}>{errors.practiceEmail}</p> */}
                                    </div>
                                    <div className='my-2 col-lg-6 col-sm-6'>
                                        <label htmlFor="practiceKey"><strong>Practice Key:</strong></label>
                                        <input 
                                        className='inputField' 
                                        name="key" 
                                        value={practiceInfo.key}
                                        onChange={handlePracticeData} 
                                        />
                                        {/* <p className="text-danger" style={style}>{errors.practiceKey}</p> */}
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-sm-12 position-relative'>
                                            <label htmlFor="practicePassword"><strong>Password:</strong></label>
                                            <input className='inputField' type={pracPass}
                                                name="password" value={practiceInfo.password} onChange={handlePracticeData} />
                                            <img src={pracPass === 'password' ? hidePassword : showPassword} alt="password" className='form-btn' onClick={showPracPass} />
                                            {/* <p className="text-danger" style={style}>{errors.practiceAcronym}</p> */}
                                        </div>
                                    </div>
                                    <div className='my-2 col-lg-6 col-sm-6'>
                                        <label htmlFor="practiceEIN"><strong>Practice EIN:</strong></label>
                                        <input  className='inputField'
                                            name="practiceEIN"
                                            value={practiceInfo.practiceEIN}
                                            onChange={handlePracticeData} 
                                            />
                                        {/* <p className="text-danger" style={style}>{errors.groupNpi}</p> */}
                                    </div>
                                    <div className='my-2 col-lg-6 col-sm-6'>
                                        <label htmlFor="practiceNPI"><strong>Practice NPI:</strong></label>
                                        <input  className='inputField'
                                            name="practiceNPI"
                                            value={practiceInfo.practiceNPI}
                                            onChange={handlePracticeData} 
                                            />
                                        {/* <p className="text-danger" style={style}>{errors.groupNpi}</p> */}
                                    </div>
                                </div>
                                <div style={{margin: '0px 8px 0px 8px'}}>
                                    <Card className="mt-2 modalCard">
                                        <CardHeader className='modalCardHead'
                                            title={<h5 className="my-2 text-white font-face-futura">Verify & Add</h5>} />
                                        <CardContent className='modalContent pb-1'>
                                            {showResult ?
                                                <div className="mb-4">
                                                    <div className="row mt-2">
                                                        <div className="col-lg-12 col-sm-12">
                                                            <span><strong>Patient ID:</strong>&nbsp;{patientInfo?.patientId}</span>
                                                        </div>
                                                        <div className="col-lg-12 col-sm-12">
                                                            <span><strong>Patient Name:</strong>&nbsp;{patientInfo?.patientName}</span>
                                                        </div>
                                                        <div className="col-lg-12 col-sm-12">
                                                            <span><strong>Patient DOB:</strong>&nbsp;{patientInfo?.patientDOB}</span>
                                                        </div>
                                                    </div>
                                                </div> : showMessage ?
                                                <div align="center" className="mb-4">
                                                    <img src={cancelIcon} alt="cancel" />
                                                    <p className="mt-3 text-center" style={{fontSize: '50px'}}>There were no results</p>
                                                </div> :
                                                <div className='row mx-0'>
                                                    <div className='col-lg-12 col-sm-12'>
                                                        <input 
                                                        className='inputField' placeholder="Search with a patient's ID from Kareo..."
                                                        name="patientId"
                                                        value={patientId}
                                                        onChange={(e) => setPatientId(e.target.value)}
                                                        />
                                                        {/* <p className="text-danger" style={{ height: 0, lineHeight: '16px', marginRight: 170 }}>{errors.patientId}</p> */}
                                                    </div>
                                                </div>
                                            }
                                            {/* {this.state.loading && patientId ? <Loader className="mb-3 mt-3 text-center" type="TailSpin" color="#10496a" height={50} width={50} /> : showResult ? showResult : showMessage} */}
                                            <footer>
                                                {showButton || showMessage ? 
                                                <div align="center" style={{marginTop: '35px'}}>
                                                    <p className='showButtonText'>{showResult ? 'Not what you were looking for?' : 'Try searching again...'}</p>
                                                    <Button fullWidth
                                                        className="mx-1 searchAgain"
                                                        variant="outlined"
                                                        onClick={handlePatientInfo}>
                                                            <span className="text-white font-face-futura">Search Again</span>
                                                    </Button>
                                                    <Button fullWidth
                                                        className="mx-1 searchAgain"
                                                        variant="outlined"
                                                        onClick={addPractice}>
                                                            <span className="text-white font-face-futura">Add Practice</span>
                                                    </Button>
                                                </div> : 
                                                <div className='mt-2' align="center">
                                                    <Button variant='contained' className='verifyLink' 
                                                    onClick={(id) => handleVerifyPractice(patientId)}
                                                    >
                                                        <span className="btnSpan">Verify Link</span>
                                                    </Button>
                                                </div>
                                                }
                                            </footer>
                                        </CardContent>
                                    </Card>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className="mt-2 modalCard practices pos-relative">
                            <CardHeader className='modalCardHead'
                                title={<h5 className="my-2 text-white font-face-futura">Practices</h5>} />
                            <CardContent className='addGroupContent px-2 pbb-0'>
                                <div className='table-responsive font-face-futura practice_table' style={{margin: '0px 8px 0px 8px'}}>
                                    <table className='table practiceTable'>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Practice Name</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {practices.map((practice, idx) => (
                                            <tr>
                                                <td>{idx+1}</td>
                                                <td>{practice.practiceName}</td>
                                                <td>
                                                    <img src={deleteIcon} alt="delete" onClick={() => removePractice(idx)} />
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </CardContent>
                        </Card>
                        <footer className='set-btn'>
                            <div className='actionButton'>
                                <Button fullWidth
                                    className="mt-4 mx-2 nevermindBtn"
                                    variant="outlined" onClick={nevermind}>
                                    <span className="btnSpan">Nevermind</span>
                                </Button>
                                <Button fullWidth
                                    className="mt-4 mx-2 addClientBtn"
                                    variant="outlined"
                                    // disabled={showResult ? false : true}
                                    onClick={handleAddClient}>
                                    <span className="btnSpan">Add Client</span>
                                </Button>
                            </div>
                        </footer>
                    </div>
                </DialogContent>
            </Dialog>}
        </>
    )
}

export default AddClient;