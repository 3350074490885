import React from 'react';
import { useSelector } from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import { Navbar } from './features/Layouts/Navbar/Navbar';
import AppRoutes from './routes';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const {userToken} = useSelector((state) => state.auth);

  return (
    <>
    <Router>
    {userToken && <Navbar />}
      <div className="App">
        <header className="App-header">
          <AppRoutes />
        </header>
      </div>
      <ToastContainer />
    </Router>
    </>
  );
}

export default App;
