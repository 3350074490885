import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle } from '@mui/material';
import editIcon from '../../../assets/icons/settingEdit.svg';
import deleteIcon from '../../../assets/icons/settingDelete.svg';
import cancelIcon from '../../../assets/icons/cancel.svg';
import { addPractice, editClient, verifyPractice } from '../adminAction';
import { editMasterSettings } from '../../Settings/settingsActions';
import hidePassword from '../../../assets/icons/hidePassword.svg';
import showPassword from '../../../assets/icons/showPassword.svg';
import DoneIcon from '@mui/icons-material/Done';

const AddPracticeModal = (props) => {
    const dispatch = useDispatch();
    const [pracPass, setPracPass] = useState('password');
    const [clientInfo, setClientInfo] = useState({
        id: '',
        clientName: '',
        provider: ''
    })
    const [practices, setPractices] = useState([]);
    const [practiceInfo, setPracticeInfo] = useState({
        practiceName: '',
        providerType: '',
        acronym: '',
        email: '',
        password: '',
        key: '',
        practiceNPI: '',
        practiceEIN: ''
    })
    const [patientId, setPatientId] = useState('');
    const [patientInfo, setPatientInfo] = useState({});
    const [showResult, setShowResult] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const clientData = props?.clientData;
        const practiceData = clientData?.practiceTables;
        setClientInfo({
            id: clientData?.id,
            clientName: clientData?.client_name,
            provider: clientData?.provider
        })
        setPractices(practiceData)
    }, [props.clientData])

    // function to toggle password visibility
    const showPracPass = (e) => {
        setPracPass(pracPass === 'password' ? 'input' : 'password' );
    }

    // function to handle input value change
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setClientInfo({...clientInfo, [name]: value});
    }

    // function to handle practice data value change
    const handlePracticeData = (e) => {
        const {name, value} = e.target;
        setPracticeInfo({...practiceInfo, [name]: value});
    }

    // function to verify patient
    const handleVerifyPractice = (patientId) => {
        console.log(patientId);
        const data = {
            patientId: patientId,
            practiceInfo: practiceInfo
        }
        dispatch(verifyPractice(data)).then((res) => {
            console.log(res);
            let patient = res.payload.data.patientInfo;
            if(res.payload.data.status === 'success'){
                setPatientInfo({
                    patientId: patient.ID,
                    patientName: patient.PatientFullName,
                    patientDOB: patient.DOB
                })
                setShowResult(true);
                setShowButton(true);
                setShowMessage(false);
            }else {
                setShowResult(false);
                setShowMessage(true);
                setShowButton(true);
            }
        })
    }

    // function to add practices
    const addClientPractice = () => {
        // let {practiceEIN, ...rest} = practiceInfo;
        // setPractices([...practices, rest]);
        const data = {...practiceInfo};
        data["id"] = clientInfo?.id;
        data["providerType"] = practiceInfo?.providerType ? practiceInfo?.providerType : 'Scheduling';
        dispatch(addPractice(data)).then((res) => {
            console.log(res.payload);
            props.closeModal();
            setShowResult(false);
            setShowButton(false);
            setShowMessage(false);
            setPatientId('')
            setPracticeInfo({
                practiceName: '',
                providerType: '',
                acronym: '',
                email: '',
                password: '',
                key: '',
                practiceNPI: '',
                practiceEIN: ''
            })
        })
    }

    // function to handle patient info
    const handlePatientInfo = () => {
        setShowResult(false);
        setShowMessage(false);
        setShowButton(false);
        setPatientId('')
    }

    // function to clear data on modal close
    const nevermind = () => {
        setPracticeInfo({
            practiceName: '',
            providerType: '',
            acronym: '',
            email: '',
            password: '',
            key: '',
            practiceNPI: '',
            practiceEIN: ''
        })
        setPractices([]);
        props.closeModal();
        setShowResult(false);
        setShowMessage(false);
        setShowButton(false);
        setPatientId('');
    }

    const style = {
        textAlign: 'left',
        height: 0,
        lineHeight: '16px'
    }
    
    return (
        <>
            {props.openModal && <Dialog fullWidth
                open={props.openModal}
                // onClose={props.closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <div className='modalTitle'>
                    <DialogTitle id="alert-dialog-title">
                        <span className='font-face-futura'>{"Add Practice"}</span>
                    </DialogTitle>
                </div>
                <DialogContent className='pos-relative'>
                    <div className='mt-2'>
                        <Card className='modalCard mb-4' style={{height: '605px'}}>
                            <CardHeader className='modalCardHead' title={<h5 className="my-2 text-white font-face-futura">Profile</h5>} />
                            <CardContent className='modalContent'>
                                <div className="row mx-0">
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="clientName"><strong>Client Name:</strong></label>
                                        <input type='text' className='inputField disabled' disabled value={clientInfo?.clientName} />
                                    </div>
                                    <div className='mt-2 col-lg-12 col-sm-12'>
                                        <label htmlFor="practiceName"><strong>Practice Name:</strong></label>
                                        <input type='text' className='inputField'
                                            name="practiceName" value={practiceInfo.practiceName} onChange={handlePracticeData} />
                                        <p className="text-danger" style={style}>{errors.practiceName}</p>
                                    </div>
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="provider"><strong>Provider Type:</strong></label>
                                        <select className='modal_select' 
                                        name='providerType' value={practiceInfo.providerType} onChange={handlePracticeData}>
                                            <option value="Scheduling">Scheduling</option>
                                            <option value="Rendering">Rendering</option>
                                        </select>
                                        <p className="text-danger" style={style}>{errors.provider}</p>
                                    </div>
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="acronym"><strong>Practice Acronym:</strong></label>
                                        <input type='text' className='inputField'
                                            name="acronym" value={practiceInfo.acronym} onChange={handlePracticeData} />
                                        <p className="text-danger" style={style}>{errors.acronym}</p>
                                    </div>
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="email"><strong>Email:</strong></label>
                                        <input type='email' className='inputField'
                                            name="email" value={practiceInfo.email} onChange={handlePracticeData} />
                                        <p className="text-danger" style={style}>{errors.email}</p>
                                    </div>
                                    <div className='col-lg-12 col-sm-12 position-relative' style={{width: '510px'}}>
                                        <label htmlFor="password"><strong>Password:</strong></label>
                                        <input className='inputField' type={pracPass}
                                            name="password" value={practiceInfo.password} onChange={handlePracticeData} />
                                        <img src={pracPass === 'password' ? hidePassword : showPassword} alt="password" className='form-btn' onClick={showPracPass} />
                                        <p className="text-danger" style={style}>{errors.password}</p>
                                    </div>
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="key"><strong>Practice Key:</strong></label>
                                        <input type='text' className='inputField'
                                            name="key" value={practiceInfo.key} onChange={handlePracticeData} />
                                        <p className="text-danger" style={style}>{errors.key}</p>
                                    </div>
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="practiceNPI"><strong>Group NPI:</strong></label>
                                        <input className='inputField'
                                            name="practiceNPI" value={practiceInfo.practiceNPI} onChange={handlePracticeData} />
                                        <p className="text-danger" style={style}>{errors.practiceNPI}</p>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className='modalCard prac'>
                            <CardHeader className='modalCardHead' title={<h5 className="my-2 text-white font-face-futura">Verify & Add</h5>} />
                            <CardContent className='modalContent'>
                                <div className='my-2'>
                                    {showResult ?
                                        <div className="mb-4">
                                            <div className="row mt-2 mx-2">
                                                <div className="col-lg-12 col-sm-12">
                                                    <span><strong>Patient ID:</strong>&nbsp;{patientInfo?.patientId}</span>
                                                </div>
                                                <div className="col-lg-12 col-sm-12">
                                                    <span><strong>Patient Name:</strong>&nbsp;{patientInfo?.patientName}</span>
                                                </div>
                                                <div className="col-lg-12 col-sm-12">
                                                    <span><strong>Patient DOB:</strong>&nbsp;{patientInfo?.patientDOB}</span>
                                                </div>
                                            </div>
                                        </div> : showMessage ?
                                        <div align="center" className="mb-4">
                                            <img src={cancelIcon} alt="cancel" />
                                            <p className="mt-3 text-center" style={{fontSize: '50px'}}>There were no results</p>
                                        </div> :
                                        <div className='row mx-0'>
                                            <div className='col-lg-12 col-sm-12'>
                                                <input 
                                                className='inputField' placeholder="Search with a patient's ID from Kareo..."
                                                name="patientId"
                                                value={patientId}
                                                onChange={(e) => setPatientId(e.target.value)}
                                                />
                                                {/* <p className="text-danger" style={{ height: 0, lineHeight: '16px', marginRight: 170 }}>{errors.patientId}</p> */}
                                            </div>
                                        </div>
                                    }
                                    {/* {this.state.loading && patientId ? <Loader className="mb-3 mt-3 text-center" type="TailSpin" color="#10496a" height={50} width={50} /> : showResult ? showResult : showMessage} */}
                                    <footer>
                                        {showButton || showMessage ? 
                                        <div align="center" style={{marginTop: '35px'}}>
                                            <p className='showButtonText'>{showResult ? 'Not what you were looking for?' : 'Try searching again...'}</p>
                                            <Button fullWidth
                                                className="mx-1 searchAgain" style={{width: '470px'}}
                                                variant="outlined"
                                                onClick={handlePatientInfo}>
                                                    <span className="text-white font-face-futura">Search Again</span>
                                            </Button>
                                        </div> : 
                                        <div className='my-3' align="center">
                                            <Button variant='contained' className='verifyLink' 
                                            onClick={(id) => handleVerifyPractice(patientId)}
                                            >
                                                <span className="btnSpan">Verify Link</span>
                                            </Button>
                                        </div>
                                        }
                                    </footer>
                                </div>
                            </CardContent>
                        </Card>
                        <footer className='set-btn'>
                            <div className='actionButton'>
                                <Button fullWidth
                                    className="mt-4 mx-2 nevermindBtn"
                                    variant="outlined" onClick={nevermind}>
                                    <span className="btnSpan">Nevermind</span>
                                </Button>
                                <Button fullWidth
                                    className={showResult ? "mt-4 mx-2 addClientBtn" : "mt-4 mx-2 linkBtn"}
                                    variant="outlined"
                                    disabled={showResult ? false : true}
                                    onClick={addClientPractice}
                                    >
                                    <span className="btnSpan">Add Practice</span>
                                </Button>
                            </div>
                        </footer>
                    </div>
                </DialogContent>
            </Dialog>}
        </>
    )
}

export default AddPracticeModal;