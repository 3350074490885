import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { createCustomRPA } from '../adminAction';

const AddRPA = (props) => {
    const dispatch = useDispatch();
    const [rpaInfo, setRpaInfo] = useState({});
    const [errors, setErrors] = useState({});

    const handleCustomRPAInputs = (e) => {
        let { name, value } = e.target;
        errors[name] = '';
        setRpaInfo({ ...rpaInfo, [name]: value });
        setErrors(errors)
    }

    // function to handle form validation
    const isFormValid = () => {
        let error = {};
        let isValid = true;
        if(!rpaInfo.rpa_id){
            isValid = false;
            error.rpa_id = "*Please enter RPA ID";
        }
        if(!rpaInfo.name){
            isValid = false;
            error.name = "*Please enter RPA Name";
        }
        if(!rpaInfo.link){
            isValid = false;
            error.link = "*Please enter API Link";
        }
        if(!rpaInfo.status){
            isValid = false;
            error.status = "*Please select Status";
        }
        setErrors(error);
        return isValid;
    }

    // function to add custom RPA
    const addCustomRPA = () => {
        if(isFormValid()){
            let body_data = {
                key: props?.client?.id,
                rpa_id: rpaInfo.rpa_id,
                name: rpaInfo.name,
                link: rpaInfo.link,
                status: rpaInfo.status ? rpaInfo.status : 'Active'
            }
            console.log(body_data)
            dispatch(createCustomRPA(body_data)).then(res => {
                console.log(res)
                setRpaInfo({
                    rpa_id: '',
                    name: '',
                    link: '',
                    status: ''
                })
                props.closeRPA();
                props.RPAList();
            })
        }
    }

    const style = {
        textAlign: 'left',
        height: 0,
        lineHeight: '16px'
    }

    return (
        <>
            {props.openRPA && <Dialog fullWidth
                open={props.openRPA}
                // onClose={props.closeRPA}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className='modalTitle'>
                    <DialogTitle id="alert-dialog-title">
                        <span className='font-face-futura'>{"Add Custom RPA"}</span>
                    </DialogTitle>
                </div>
                <DialogContent className='pos-relative'>
                    <div className='mt-2'>
                        <Card className='modalCard mb-4' style={{ height: '410px' }}>
                            <CardHeader className='modalCardHead' title={<h5 className="my-2 text-white font-face-futura">Profile</h5>} />
                            <CardContent className='modalContent'>
                                <div className="row mx-0">
                                    <div className='mb-3 col-lg-12 col-sm-12'>
                                        <label htmlFor="clientName"><strong>Client Name:</strong></label>
                                        <input type='text' className='inputField disabled' disabled name="client" value={props?.client?.client_name} />
                                    </div>
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="rpa_id"><strong className='strongTxt'>RPA ID:</strong></label>
                                        <input className='inputField' value={rpaInfo?.rpa_id}
                                            name="rpa_id" onChange={handleCustomRPAInputs} />
                                        <p className="text-danger" style={style}>{errors.rpa_id}</p>
                                    </div>
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="name"><strong className='strongTxt'>RPA Name:</strong></label>
                                        <input className='inputField' value={rpaInfo?.name}
                                            name="name" onChange={handleCustomRPAInputs} />
                                        <p className="text-danger" style={style}>{errors.name}</p>
                                    </div>
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="link"><strong className='strongTxt'>API Link:</strong></label>
                                        <input className='inputField' value={rpaInfo?.link}
                                            name="link" onChange={handleCustomRPAInputs} />
                                        <p className="text-danger" style={style}>{errors.link}</p>
                                    </div>
                                    <div className='col-lg-12 col-sm-12'>
                                        <label htmlFor="status"><strong className='strongTxt'>Status:</strong></label>
                                        <select className='modal_select'
                                            name='status' value={rpaInfo?.status} onChange={handleCustomRPAInputs}>
                                            <option value="">Select</option>
                                            <option value="Active">Active</option>
                                            <option value="Deactivated">Deactivated</option>
                                            <option value="Paused">Paused</option>
                                        </select>
                                        <p className="text-danger" style={style}>{errors.status}</p>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <footer className='set-btn'>
                            <div className='actionButton'>
                                <Button fullWidth
                                    className="mt-2 mx-2 nevermindBtn"
                                    variant="outlined" onClick={props.closeRPA}>
                                    <span className="btnSpan">Nevermind</span>
                                </Button>
                                <Button fullWidth
                                    className={!(rpaInfo.rpa_id && rpaInfo.name && rpaInfo.link && rpaInfo.status) ? "mt-2 mx-2 disableBtn" : "mt-2 mx-2 addClientBtn"}
                                    variant="outlined"
                                    disabled={!(rpaInfo.rpa_id && rpaInfo.name && rpaInfo.link && rpaInfo.status) ? true : false}
                                    onClick={addCustomRPA}>
                                    <span className="btnSpan">Add</span>
                                </Button>
                            </div>
                        </footer>
                    </div>
                </DialogContent>
            </Dialog>}
        </>
    )
}

export default AddRPA;